
export const sanitizeHTML = (html) => {
    const sanitizedHTML = html.replace(/<(\w+)[^>]*>.*?<\/\1>|<.*?\/>/g, tag => {
        return tag; // Preserve complete tags
    });
    return sanitizedHTML;
}

export const formatNotificationTime = (timestamp) => {
    let date = new Date(timestamp);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // Month is 0-indexed
    let day = date.getDate();

    // Format date to MM/DD/YYYY
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    let ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; 

    minutes = minutes < 10 ? '0' + minutes : minutes;

    return (`${month}-${day}-${year} ${hours}:${minutes} ${ampm}`);
}

