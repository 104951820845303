import { Box, Card, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';

export default function UnauthComponent(props) {
  console.log(props, 'propsprops');
  // const typedURL = props?.location?.state;
  // const [unAuthURL, setUnAuthURL] = useState('');
  // useEffect(() => {
  //   setUnAuthURL(typedURL);
  // }, [typedURL]);

  useEffect(() => {
    const disableRightClick = event => {
      event.preventDefault();
    };

    // Add event listener to disable right-click
    window.addEventListener('contextmenu', disableRightClick);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh', // Full viewport height
        backgroundColor: '#f9f9f9' // Subtle background
      }}
    >
      <Card
        sx={{
          padding: 4,
          maxWidth: 500,
          // whiteSpace: 'nowrap',
          textAlign: 'center',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Soft shadow
          borderRadius: 3,
          backgroundColor: '#ffffff'
        }}
      >
        <Typography
          variant='h6'
          sx={{
            color: red[800],
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        >
          Module for entered URL is not Assigned by Admin
        </Typography>
        <Typography
          variant='body1'
          sx={{
            color: '#555'
          }}
        >
          This component has not been assigned by the admin yet. Please contact
          the administrator for further assistance.
        </Typography>
      </Card>
    </Box>
  );
}
