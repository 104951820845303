import { Box, Typography } from '@material-ui/core';
import { Badge, Button, Skeleton, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';

import NotificationCard from './NotificationCard';
import { useSelector } from 'react-redux';
import { formatNotificationTime, sanitizeHTML } from '../../apis/Utils';
import { GetAppNotifications } from '../../apis/Agency';
import NotificationDialog from './NotificationDialog';

function Notifications() {


    const [readNotifications, setReadNotifications] = useState([]);
    const [unreadNotifications, setUnReadNotifications] = useState([]);

    const [loading, setLoading] = useState(false);


    const [open, setOpen] = useState(false);
    const [notifView, setNotifView] = useState({});
    const handleClose = () => {
        setOpen(false);
    };




    const fetchNotifications = () => {
        setLoading(true);
        GetAppNotifications(false, 0).then(res => {
            setReadNotifications(res?.data?.data?.filter(notif => notif.isRead));
            setUnReadNotifications(res?.data?.data?.filter(notif => !notif.isRead));

            setLoading(false);

        }).catch(err => {
            console.log(err, 'appNotificationError')
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchNotifications();
    }, [])




    const Loader = () => {
        return (
            [1, 2, 3, 4].map((v) => (
                <Skeleton variant='rounded' height={100} className='mb-2' />
            ))
        )
    }

    const Notiffs = ({ notifarray }) => {

        return (
            <Box className='d-flex flex-column gap-2'>
                {notifarray?.map(notiff => (
                    <NotificationCard
                        id={notiff.id}
                        indicator={false}
                        titleLength={50}
                        status={!notiff.isRead}
                        title={notiff.title}
                        description={sanitizeHTML(notiff.message)}
                        time={formatNotificationTime(notiff.receivedOn)}
                        setGlobalOpen={setOpen}
                        setGlobalNotification={setNotifView}
                        reFetch={fetchNotifications}
                    />
                ))}
            </Box>
        )
    }


    return (
        <Box sx={{ p: 2, pt: 0 }} className='w-100 d-flex justify-content-center'>


            <NotificationDialog notifView={notifView} isOpen={open} handleClose={handleClose} />

            <Box className='d-flex gap-4' style={{ width: '80%' }}>
                <div className='w-50 d-flex flex-column'>
                    <Box className='my-1 mb-2 p-2 bg-blue text-white rounded'>
                        <Typography>New {(!loading || open) && `(${unreadNotifications?.length})`}</Typography>
                    </Box>
                    <div
                        className='w-100 d-flex flex-column'
                        style={{ maxHeight: '78vh', overflow: 'auto' }}
                    >
                        {(loading && !open) ?
                            <Loader />
                            :
                            <Notiffs notifarray={unreadNotifications} />
                        }
                    </div>
                </div>

                <div
                    className='h-100'
                    style={{ minHeight: '78vh', borderLeft: '2px solid #ddd' }}
                />

                <div className='w-50 d-flex flex-column'>
                    <Box className='my-1 mb-2 p-2 bg-blue text-white rounded'>
                        <Typography>Earlier {(!loading || open) && `(${readNotifications?.length})`}</Typography>
                    </Box>
                    <div
                        className='w-100 d-flex flex-column'
                        style={{ maxHeight: '78vh', overflow: 'auto' }}
                    >
                        {(loading && !open) ?
                            <Loader />
                            :
                            <Notiffs notifarray={readNotifications} />
                        }
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default Notifications;
