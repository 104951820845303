import React from "react";
import { AppNotificationMarkAsRead } from "../../apis/Agency";

const NotificationCard = ({ id, title, subtitle, description, time, status, indicator = true, titleLength = 25, subtitleLength = 32, setGlobalOpen, setGlobalNotification, reFetch = function () { } }) => {

    const handleOpenNotification = () => {

        setGlobalNotification({
            title: title,
            description: description,
            time: time
        });
        setGlobalOpen(true)


        if (status) {
            AppNotificationMarkAsRead(id).then(res => {
                reFetch();
            }).catch(er => {
                console.log(er)
            })
        }

    }

    return (
        <div style={{ borderBottom: "1px solid #ddd", textAlign: 'left', cursor: 'pointer' }} className="d-flex align-items-start bg-white p-2 pointer" onClick={() => handleOpenNotification()}>
            {indicator &&
                <div style={status ? styles.newIndicator : styles.readIndicator}></div>
            }

            <div style={{ flex: 1 }} className="">

                <div className="d-flex align-items-center justify-content-between">
                    {title ?
                        <p style={styles.title} className="">{title.slice(0, titleLength)} {title.length > titleLength && '...'}</p>
                        : <p></p>
                    }
                    {status}
                    <p style={styles.timestamp}>{time}</p>
                </div>
                {subtitle &&
                    <p style={styles.subtitle}>{subtitle.slice(0, subtitleLength)} {subtitle.length > subtitleLength && '...'}</p>
                }
                {/* <p style={styles.description}>{description}</p> */}


                <div dangerouslySetInnerHTML={{ __html: description }} style={{ maxHeight: 150, overflow: 'hidden' }} /> {'...'}
            </div>

        </div>
    );
};

const styles = {

    newIndicator: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#007bff",
        marginRight: "10px",
        marginTop: "5px",
    },

    readIndicator: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#aaaaaa50",
        marginRight: "10px",
        marginTop: "5px",
    },


    title: {
        margin: 0,
        fontWeight: "bold",
        color: "#333",
    },
    subtitle: {
        margin: "5px 0 0 0",
        fontWeight: "bold",
        color: "#555",
    },
    description: {
        margin: "5px 0 0 0",
        color: "#888",
        fontSize: "14px",
    },
    timestamp: {
        fontSize: "12px",
        color: "#aaa",
        margin: 0,
    },
};

export default NotificationCard;




