import React, { useEffect, useState } from 'react';
import Routes from './Routes/index';

//import Custom Style scss
import './assets/scss/themes.scss';
import { useDispatch, useSelector, } from 'react-redux';
import { useIsMobile } from './components/IsMobileHook/IsMobileHook';
import { toast, ToastContainer } from 'react-toastify';
import { GetAppNotifications } from './apis/Agency';
import { setUserAppNotifications } from './Redux/UserSlice';
import { Snackbar } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import NotificationCard from './pages/Notifications/NotificationCard';


function SignalRApp() {
    const dispatch = useDispatch();


    const UserNotificationsObj = useSelector(
        state => state.user.UserAppNotifications
    );

    const [UserNotifications, setUserNotifications] = useState();

    useEffect(() => {
        setUserNotifications(UserNotificationsObj?.notifications || [])
    }, [UserNotificationsObj])


    const isMobile = useIsMobile();
    // , {
    //     content: (key) => <NotificationCard id={key} title={notif.title} />
    // }

    const succRes = (apiRes) => {

        const oldIds = UserNotifications.map(notif => notif.id);
        apiRes?.data.forEach(notif => {
            if (!oldIds.includes(notif.id)) {
                enqueueSnackbar(notif.title);
            }
        });

        dispatch(setUserAppNotifications({
            notifications: apiRes?.data,
            count: apiRes?.pagingInfo?.pageSize - 1
        }))
    }

    const fetchAppNotifications = () => {

        GetAppNotifications(true, 5)
            .then(res => succRes(res?.data))
            .catch(err => {
                console.log(err, 'appNotificationError');
            });

    }

    useEffect(() => {
        fetchAppNotifications();
        const interval = setInterval(() => {
            fetchAppNotifications();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div
        // style={
        //   layoutMode === 'dark'
        //     ? { backgroundColor: '#303841' }
        //     : { backgroundColor: '#EDF2F9' }
        // }
        >
            <SnackbarProvider maxSnack={5} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} className='bg-white text-black' />

            <Routes />
        </div>
    );
}

export default SignalRApp;
