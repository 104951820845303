import React, { useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import { Col, Label, Row, Button } from 'reactstrap';
import {
  CardContent,
  Card,
  FormControlLabel,
  InputAdornment,
  Paper,
  TextField,
  Box,
  createFilterOptions,
  Grid,
  Chip,
  CardActionArea,
  CardActions,
  CircularProgress,
  styled,
  Slider,
  Stack
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import MergeTypeIcon from '@material-ui/icons/LocalShippingSharp';
import LocationIcon from '@material-ui/icons/PersonPinSharp';
import PaymentIcon from '@material-ui/icons/PaymentSharp';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MetaTags } from 'react-meta-tags';
import {
  CalculateDistance,
  GetAagencyUserDetails,
  GetLocationsJSON
} from '../../apis/Agency';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setClassDimensionsButton } from '../../Redux/ClassDimensionsBtnSlice';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import {
  setQuickQuoteDeliveryCheckedRedux,
  setQuickQuotePickUpCheckedRedux
} from '../../Redux/QuickQuoteSwevicesSlice';
import { setClassRows } from '../../Redux/QuickQuoteAddedRows';
import { setDimensionRows } from '../../Redux/QuickQuoteDimenionsAddedRows';
import {
  setClassWeight,
  setDimensionsWeight
} from '../../Redux/QuickQuoteTotalWeightSlice';
import { base_logo_url, base_url } from '../../apis/constants';
import ReactQuill from 'react-quill';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import { setQuickQuoteRateLoc } from '../../Redux/QuickQuoteRateSlice';
import { calculateDistance } from '../../widgets/CalculateFunctions';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';

function QuickQuote(props) {
  const today = new Date().toISOString().split('T')[0];
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [countryCode, setCountryCode] = useState();
  const [shipCity, setShipCity] = useState([]);

  const handleUSAddresses = (apiRes, type) => {
    if (type === 'shipFrom') {
      setShipFromHoveredValue(apiRes[0]);
    } else {
      setShipToHoveredValue(apiRes[0]);
    }
    setShipCity(apiRes);
    setShipFromloading(false);
    setShipToloading(false);
  };

  var layoutMode = document.body.getAttribute('data-layout-mode');
  const selectLocations = useRef(null);
  const selectShipFrom = useRef(null);
  const selectShipTo = useRef(null);
  const enterWeight = useRef(null);

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const UserDetails = useSelector(state => state.userDetails);
  const viewShipmentsData = useSelector(state => state.getShipments);
  const SelectedRates = useSelector(state => state.quickQuoteRate);
  const SelectedLoc = useSelector(
    state => state.quickQuoteLoc.QuickQuoteRateLoc
  );
  const AddedClassRows = useSelector(state => state.newAddedPersistedRows);
  const AddedDimensionsRows = useSelector(
    state => state.newAddedPersistedDimensionsRows
  );
  const getPickupOption = option =>
    SelectedRates?.pickupOptions?.[option] ?? false;

  const getDeliveryOption = option =>
    SelectedRates?.deliveryOptions?.[option] ?? false;

  const ServicesSwitchChecks = useSelector(
    state => state.quickQuoteServicesSlice
  );
  let PersistedPaymentType = SelectedRates?.paymentType;
  // const SelectedRatesPaymentType =
  //   PaymentType === '0'
  //     ? 'Outbound Prepaid'
  //     : PaymentType === '1'
  //     ? 'Inbound Collect'
  //     : 'Third Party';
  const ClassDimBtn = useSelector(state => state.classDimensionsBtn);
  const TotalClassPersistedWeight = useSelector(
    state => state.totalPersistedWeightLBS.ClassWeight
  );
  const TotalDimensionsPersistedWeight = useSelector(
    state => state.totalPersistedWeightLBS.DimensionsWeight
  );
  const selectedTableRowID = props?.location.state;
  const dispatch = useDispatch();
  // const useStyles = makeStyles({
  //   inputRoot: {
  //     '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
  //       '-webkit-appearance': 'none',
  //       margin: 0
  //     },
  //     '& input[type=number]': {
  //       '-moz-appearance': 'textfield'
  //     }
  //   }
  // });
  const classes = useStyles();
  const [shipLocationValue, setShipLocationValue] = useState(
    SelectedRates?.location ? SelectedRates?.location : ''
  );
  //pickup switch states

  const [insidePickupSwitch, setInsidePickupSwitch] = useState(
    getPickupOption('inside')
  );
  const [liftgatePickupSwitch, setLiftgatePickupSwitch] = useState(
    getPickupOption('liftGateServices')
  );
  const [residentialPickupSwitch, setResidentialPickupSwitch] = useState(
    getPickupOption('residential')
  );
  const [limitedPickupAccessSwitch, setLimitedPickupAccessSwitch] = useState(
    getPickupOption('limitedAccess')
  );
  const [secureAccesPickupSwitch, setSecureAccesPickupSwitch] = useState(
    getPickupOption('secureAccess')
  );
  const [freezeProtectionPickupSwitch, setFreezeProtectionPickupSwitch] =
    useState(getPickupOption('freezeProtection'));
  const [overlengthPickupSwitch, setOverlengthPickupSwitch] = useState(
    getPickupOption('overLength')
  );

  //delivery switch states

  const [insideDeliverySwitch, setInsideDeliverySwitch] = useState(
    getDeliveryOption('inside')
  );
  const [liftgateDeliverySwitch, setLiftgateDeliverySwitch] = useState(
    getDeliveryOption('liftGateServices')
  );
  const [residentialDeliverySwitch, setResidentialDeliverySwitch] = useState(
    getDeliveryOption('residential')
  );
  const [limitedDeliverySwitch, setLimitedDeliverySwitch] = useState(
    getDeliveryOption('limitedAccess')
  );
  const [secureAccesDeliverySwitch, setSecureAccesDeliverySwitch] = useState(
    getDeliveryOption('secureAccess')
  );

  const [
    callBeforeDeliveryServicesSwitch,
    setCallBeforeDeliveryServicesSwitch
  ] = useState(getDeliveryOption('callBeforeDelivery'));

  const LTLPaymentTypeArr = [
    'Outbound Prepaid',
    'Inbound Collect',
    'Third Party'
  ];
  const parcelPaymentTypeArr = ['Sender', 'Receiver', 'Third Party'];
  const [shipTypeOptions, setShipTypeOptions] = useState([]);
  const [hazmatSwitch, setHazmatSwitch] = useState(
    SelectedRates?.isHazmat ? SelectedRates?.isHazmat : false
  );
  const [stackableSwitch, setStackableSwitch] = useState(false);
  const [pickUpChecked, setPickUpChecked] = useState(
    ServicesSwitchChecks.QuickQuotePickUpCheckedRedux
      ? ServicesSwitchChecks.QuickQuotePickUpCheckedRedux
      : false
  );
  const [overLengthValue, setOverLengthValue] = useState(
    SelectedRates?.pickupOptions?.overLengthValue
      ? SelectedRates?.pickupOptions?.overLengthValue
      : ''
  );
  const [deliveryChecked, setDeliveryChecked] = useState(
    ServicesSwitchChecks.QuickQuoteDeliveryCheckedRedux
      ? ServicesSwitchChecks.QuickQuoteDeliveryCheckedRedux
      : false
  );

  // const [viewShipmentsData, setViewShipmentsData] = useState([]);
  const [shipmentDate, setShipmentDate] = useState(materialDateInput);
  const [deliveryDate, setDeliveryDate] = useState(materialDateInput);
  const DataToShow = viewShipmentsData.ShipmentDetails[selectedTableRowID];
  const [shipFromloading, setShipFromloading] = useState(false);
  const [shipToloading, setShipToloading] = useState(false);
  const ShipmentType = DataToShow?.shipmentType;
  const ShipTypeArr = ['LTL', 'Parcel', 'TL'];
  // const ShipTypeArr = ['LTL'];

  const LTLUnitTypeArr = ['Pallet', 'Tote', 'Box', 'Drum'];
  const ParcelUnitTypeArr = ['Box', 'Envelope'];

  const [shipTypeValue, setShipTypeValue] = useState(
    SelectedRates?.shipmentType ? SelectedRates?.shipmentType : ShipTypeArr[0]
  );
  const [unitTypeValue, setUnitTypeValue] = useState(LTLUnitTypeArr[0]);

  const [validationErrors, setValidationErrors] = useState({});

  const [shipLocation, setShipLocation] = useState([]);
  const [userLocationsArr, setUserLocationsArr] = useState([]);
  const [shipLocationToShow, setShipLocationToShow] = useState([]);
  const [shipLocationLoader, setShipLocationLoader] = useState(false);

  const [shipLocationID, setShipLocationID] = useState(
    SelectedRates?.locationId ? SelectedRates?.locationId : 0
  );
  const [paymentTypeValue, setPaymentTypeValue] = useState(
    PersistedPaymentType === '0'
      ? // ? SelectedRates.shipmentType === 'Parcel'
        //   ? 'Sender'
        //   :
        'Outbound Prepaid'
      : PersistedPaymentType === '1'
      ? // ? SelectedRates.shipmentType === 'Parcel'
        //   ? 'Receiver'
        //   :
        'Inbound Collect'
      : PersistedPaymentType === '2'
      ? 'Third Party'
      : 'Outbound Prepaid'
  );
  const [backBtnClicked, setBackBtnClicked] = useState(false);
  const [quickWeightLBS, setQuickWeightLBS] = useState('');
  const [finalCommodityInfoApi, setFinalCommodityInfoApi] = useState(
    SelectedRates?.commodityInfo ? SelectedRates?.commodityInfo : []
  );
  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];

  const VehicleArray = ['Dry Van', 'Flatbed', 'Reefer', 'Tanker', 'Intermodal'];
  const [classArrayValue, setClassArrayValue] = useState(ClassArray[0]);
  const CommodityInfoArray = [
    {
      WeightLBS: '',
      Class: classArrayValue,
      NMFCnumber: ''
    }
  ];
  const [NMFCnumber, setNMFCnumber] = useState('');
  const [dimensionsWeightLBS, setDimensionsWeightLBS] = useState('');
  const [dimensionsWeightLBSErr, setDimensionsWeightLBSErr] = useState(false);
  const [nmbOfunitsErr, setNmbOfunitsErr] = useState(false);
  const [nmbOfunits, setNmbOfunits] = useState('');
  const [dimensions, setDimensions] = useState({
    Lenght: '',
    Width: '',
    Height: ''
  });
  const [dimensionsValueErr, setDimensionsValueErr] = useState({
    Lenght: false,
    Width: false,
    Height: false
  });
  const [CODAmount, setCODAmount] = useState(
    SelectedRates?.codAmount ? SelectedRates.codAmount : ''
  );
  const [DeclaredValue, setDeclaredValue] = useState(
    SelectedRates?.declaredValue ? SelectedRates.declaredValue : ''
  );
  const [getRateLoading, setGetRateLoading] = useState(false);
  const [selectClassErr, setSelectClassErr] = useState(false);
  const [dimensionsErr, setDimensionsErr] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [shipFromValue, setShipFromValue] = useState('');
  const [shipToValue, setShipToValue] = useState('');
  const [freightClass, setFreightClass] = useState('');
  const [UnitTypeName, setUnitTypeName] = useState('Pallet');
  const [newAddedDimensionsRow, setNewAddedDimensionsRow] = useState([
    {
      Class: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].freightClass
        : '50',
      WeightLBS: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].weight?.value
        : '',
      UnitTypeValue: SelectedRates?.commodityInfo?.[0]?.packaging
        ? SelectedRates?.commodityInfo?.[0]?.packaging
        : LTLUnitTypeArr[0],
      nmfc: '',
      NmbOfUnits: SelectedRates?.commodityInfo?.[0]?.pallets
        ? SelectedRates?.commodityInfo?.[0].pallets
        : '1',
      Pieces: SelectedRates?.commodityInfo?.[0]?.pieces
        ? SelectedRates?.commodityInfo?.[0].pieces
        : '1',
      StackAble: SelectedRates?.commodityInfo?.[0].dimensions?.isStackable
        ? SelectedRates?.commodityInfo?.[0].dimensions?.isStackable
        : false,
      Length: SelectedRates?.commodityInfo?.[0].dimensions?.length
        ? SelectedRates?.commodityInfo?.[0].dimensions.length
        : '',
      Width: SelectedRates?.commodityInfo?.[0].dimensions?.width
        ? SelectedRates?.commodityInfo?.[0].dimensions.width
        : '',
      Height: SelectedRates?.commodityInfo?.[0].dimensions?.height
        ? SelectedRates?.commodityInfo?.[0].dimensions?.height
        : ''
    }
  ]);

  const [newAddedClassRow, setNewAddedClassRow] = useState([
    {
      WeightLBS: SelectedRates?.commodityInfo?.[0]
        ? SelectedRates?.commodityInfo?.[0].weight?.value
        : '',
      Class: ClassArray[0],
      NMFCnumber: ''
    }
  ]);
  const [totalLBSWeightClass, setTotalLBSWeightClass] = useState('');

  const [totalLBSWeightDimensions, setTotalLBSWeightDimensions] = useState('');
  const [totalDimensionsWeightSum, setTotalDimensionsWeightSum] = useState(
    TotalDimensionsPersistedWeight
  );
  const [totalClassWeightSum, setTotalClassWeightSum] = useState(
    TotalClassPersistedWeight
  );

  const [alignment, setAlignment] = useState(
    ClassDimBtn ? ClassDimBtn : 'Class'
  );
  const [isOverLength, setIsOverLength] = useState(
    SelectedRates?.pickupOptions?.overLength
      ? SelectedRates?.pickupOptions?.overLength
      : false
  );
  const [grossWeight, setGrossWeight] = useState('');
  const DetailsArr = [];
  const ShipFrom =
    SelectedRates?.shipFrom?.city +
    ' ' +
    SelectedRates?.shipFrom?.stateOrProvinceCode +
    ' ' +
    SelectedRates?.shipFrom?.postalCode +
    ' ' +
    SelectedRates?.shipFrom?.countryCode;

  const ShipTo =
    SelectedRates?.shipTo?.city +
    ' ' +
    SelectedRates?.shipTo?.stateOrProvinceCode +
    ' ' +
    SelectedRates?.shipTo?.postalCode +
    ' ' +
    SelectedRates?.shipTo?.countryCode;
  const WeightLBS = DataToShow?.details[0].grossWeight;
  const WeightPieces = DataToShow?.details[0].pieces;
  const UserLoggedInEmail = useSelector(state => state.userDetails.email);
  const [locationHoveredValue, setLocationHoveredValue] = useState(null);
  const [locationInputChange, setLocationInputChange] = useState(false);
  const [paymentTypeHoveredValue, setPaymentTypeHoveredValue] = useState(null);
  const [paymentTypeInputChange, setPaymentTypeInputChange] = useState(false);
  const [shipFromHoveredValue, setShipFromHoveredValue] = useState(null);
  const [shipFromInputChange, setShipFromInputChange] = useState(false);
  const [shipToHoveredValue, setShipToHoveredValue] = useState(null);
  const [shipToInputChange, setShipToInputChange] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    SelectedLoc ? SelectedLoc : ''
  );
  const [freightClassHoveredValue, setFreightClassHoveredValue] =
    useState(null);
  const [vehicleNameHoveredValue, setVehicleNameHoveredValue] = useState(null);
  const [unitTypeHoveredValue, setUnitTypeHoveredValue] = useState(null);
  const [openShipFrom, setOpenShipFrom] = useState(false);
  const [openShipTo, setOpenShipTo] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    SelectedRates?.shipmentType === 'LTLParcel' ||
      SelectedRates?.shipmentType === 'ParcelLTL'
      ? // SelectedRates?.shipmentType === 'ParcelTL' ||
        // SelectedRates?.shipmentType === 'ParcelLTL'
        ShipTypeArr.filter(item => item !== 'TL')
      : SelectedRates?.shipmentType
      ? [ShipTypeArr.find(shipType => shipType === SelectedRates?.shipmentType)]
      : [ShipTypeArr[0]]
  );

  const selectedLocDetails = userLocationsArr?.filter(
    items => items.id === shipLocationID
  );
  console.log(selectedLocDetails, 'selectedLocDetails');
  // useEffect(() => {
  //   // dispatch(setQuickQuoteRateValues(''));
  //   // Cleanup function
  //   return () => {
  //     // Clear the values when the component is unmounted or user navigates away
  //     dispatch(setQuickQuoteRateValues(''));
  //   };
  // }, []); // Empty dependency array to run the effect only once on mount

  // Add another useEffect for handling page refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the values when the page is being refreshed
      dispatch(setQuickQuoteRateValues(''));
      dispatch(setQuickQuoteRateLoc(''));
      dispatch(setQuickQuotePickUpCheckedRedux(false));
      dispatch(setQuickQuoteDeliveryCheckedRedux(false));
      // dispatch(setClassDimensionsButton(alignment));
      // dispatch(setClassRows(newAddedClassRow));
      // dispatch(setDimensionRows(newAddedDimensionsRow));
      // dispatch(setClassWeight(totalClassWeightSum));
      dispatch(setDimensionsWeight(''));
    };

    // Attach the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function for removing the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setOpenShipFrom(false);
    setOpenShipTo(false);

    let ToalWeightLBS = 0;
    if (AddedClassRows) {
      for (let i = 0; i < AddedClassRows.length; i++) {
        ToalWeightLBS += parseInt(AddedClassRows[i].WeightLBS);
      }
      setTotalLBSWeightClass(ToalWeightLBS);
    }
  }, []);

  useEffect(() => {
    let ToalWeightLBS = 0;
    if (AddedDimensionsRows) {
      for (let i = 0; i < AddedDimensionsRows.length; i++) {
        ToalWeightLBS += parseInt(AddedDimensionsRows[i].WeightLBS);
      }
      setTotalLBSWeightDimensions(ToalWeightLBS);
    }
  }, []);
  const [carrierApiNames, setCarrierApiNames] = useState([]);
  const [TlCarrierApiNames, setTlCarrierApiNames] = useState([]);
  const [ParcelCarrierApiNames, setParcelCarrierApiNames] = useState([]);

  useEffect(() => {
    let totalWeight;
    if (SelectedRates?.commodityInfo?.[0]?.weight?.value) {
      const copy1 = JSON.parse(JSON.stringify(AddedClassRows));
      setNewAddedClassRow(copy1);
      const copy2 = JSON.parse(JSON.stringify(AddedDimensionsRows));

      setNewAddedDimensionsRow(copy2);

      if (alignment === 'Dimensions') {
        setTotalDimensionsWeightSum(
          parseInt(SelectedRates.commodityInfo[0]?.weight?.value)
        );
      }

      if (alignment === 'Class') {
        setTotalClassWeightSum(
          parseInt(SelectedRates.commodityInfo[0]?.weight?.value)
        );
      }
    }
    let FinalObj = {};
    if (
      !ShipFrom.includes('undefined') &&
      (paymentTypeValue === 'Third Party' ||
        paymentTypeValue === 'Inbound Collect')
    ) {
      // const tempData = ShipFrom.split(' ');
      // const City = tempData[0];
      // const State = tempData[1];
      // const ZipCode = tempData[2];
      // FinalObj = {
      //   City,
      //   State,
      //   ZipCode
      // };

      const parseAddress = address => {
        const addressParts = address.split(' ');
        let city = '';
        let state = '';
        let zipCode = '';
        let countryCode = '';

        for (let i = 0; i < addressParts.length; i++) {
          const part = addressParts[i];

          // Check if the part is a state code (e.g., TX)
          if (part.length === 2 && /^[A-Z]+$/.test(part)) {
            state = part;
          }
          // Check if the part is a zip code
          else if (
            (part.length === 5 || part.length === 6) &&
            /^\d+$/.test(part)
          ) {
            zipCode = part;
          }
          // Assume it's the city if not a state code or zip code
          else if (part.length === 3) {
            countryCode = part;
          } else {
            city += part + ' ';
          }
        }

        // Trim any extra spaces from the city
        city = city.trim();

        return { city, state, zip: zipCode, countryCode: countryCode };
      };
      const tempData = ShipFrom;
      const parsedAddress = parseAddress(tempData);
      const City = parsedAddress.city;
      const State = parsedAddress.state;
      const ZipCode = parsedAddress.zip;
      const CountryCode = parsedAddress.countryCode;
      FinalObj = {
        City,
        State,
        ZipCode,
        CountryCode
      };
      setShipFromFinalAddress(FinalObj);
    }

    if (
      !ShipTo.includes('undefined') &&
      paymentTypeValue !== 'Inbound Collect'
    ) {
      const parseAddress = address => {
        const addressParts = address.split(' ');
        let city = '';
        let state = '';
        let zipCode = '';
        let countryCode = '';

        for (let i = 0; i < addressParts.length; i++) {
          const part = addressParts[i];

          // Check if the part is a state code (e.g., TX)
          if (part.length === 2 && /^[A-Z]+$/.test(part)) {
            state = part;
          }
          // Check if the part is a zip code
          else if (
            (part.length === 5 || part.length === 6) &&
            /^\d+$/.test(part)
          ) {
            zipCode = part;
          } else if (part.length === 3) {
            countryCode = part;
          }
          // Assume it's the city if not a state code or zip code
          else {
            city += part + ' ';
          }
        }

        // Trim any extra spaces from the city
        city = city.trim();

        return { city, state, zip: zipCode, countryCode: countryCode };
      };
      const tempData = ShipTo;
      const parsedAddress = parseAddress(tempData);
      const City = parsedAddress.city;
      const State = parsedAddress.state;
      const ZipCode = parsedAddress.zip;
      const CountryCode = parsedAddress.countryCode;
      FinalObj = {
        City,
        State,
        ZipCode,
        CountryCode
      };
      //   const tempData = ShipTo.split(' ');

      // const City = tempData[0];
      // const State = tempData[1];
      // const ZipCode = tempData[2];
      // FinalObj = {
      //   City,
      //   State,
      //   ZipCode
      // };
      setShipToFinalAddress(FinalObj);
    }
    // else {
    //   const tempData = ShipTo.split(' ');
    //   const City = tempData[0];
    //   const State = tempData[1];
    //   const ZipCode = tempData[2];
    //   FinalObj = {
    //     City,
    //     State,
    //     ZipCode
    //   };
    //   setShipToFinalAddress(FinalObj);
    // }
  }, []);

  const handleDimensionChange = (name, dimensionsVal) => {
    if (name === 'length') {
      setDimensions({ ...dimensions, Lenght: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Lenght: false });
    } else if (name === 'width') {
      setDimensions({ ...dimensions, Width: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Width: false });
    } else setDimensions({ ...dimensions, Height: dimensionsVal });
    setDimensionsValueErr({ ...dimensionsValueErr, Height: false });
  };

  const handleGetRates = e => {
    e.preventDefault();
    setOpenShipFrom(false);
    if (dimensionsErr) {
      if (!dimensionsWeightLBS) {
        setDimensionsWeightLBSErr(true);
      }
      if (!nmbOfunits) {
        setNmbOfunitsErr(true);
      }
      if (!dimensions.Lenght) {
        setDimensionsValueErr({ ...dimensionsValueErr, Lenght: true });
      }
      if (!dimensions.Width) {
        setDimensionsValueErr({ ...dimensionsValueErr, Width: true });
      }
      if (!dimensions.Height) {
        setDimensionsValueErr({ ...dimensionsValueErr, Height: true });
      }
    }
    if (!classArrayValue) {
      setSelectClassErr(true);
    } else if (classArrayValue) {
      setSelectClassErr(false);
      // setGetRateLoading(true);
      let OutbondShipper;
      let OutbondReciever;
      let PersistedShipFrom;
      let PersistedShipTo;

      let ApiCityFrom = '';
      let ApiStateFrom = '';
      let ApiZipFrom = '';
      let ApiZipFromCountryCode = '';

      if (
        paymentTypeValue === 'Outbound Prepaid' &&
        // ||
        // paymentTypeValue === 'Sender') &&
        !shipFromFinalAddress.City
      ) {
        OutbondShipper = shipFromFinalAddress?.split(' ');
        ApiCityFrom =
          paymentTypeValue === 'Outbound Prepaid'
            ? // ||
              // paymentTypeValue === 'Sender'
              OutbondShipper[0]
            : shipFromFinalAddress.City;
        ApiStateFrom =
          paymentTypeValue === 'Outbound Prepaid'
            ? // ||
              // paymentTypeValue === 'Sender'
              OutbondShipper[1]
            : shipFromFinalAddress.State;
        ApiZipFrom =
          paymentTypeValue === 'Outbound Prepaid'
            ? // ||
              // paymentTypeValue === 'Sender'
              OutbondShipper[2]
            : shipFromFinalAddress.ZipCode;
        ApiZipFromCountryCode =
          paymentTypeValue === 'Outbound Prepaid'
            ? // ||
              // paymentTypeValue === 'Sender'
              OutbondShipper[3]
            : shipFromFinalAddress.CountryCode;
      } else {
        ApiCityFrom = shipFromFinalAddress.City;
        ApiStateFrom = shipFromFinalAddress.State;
        ApiZipFrom = shipFromFinalAddress.ZipCode;
        ApiZipFromCountryCode = shipFromFinalAddress.CountryCode;
      }

      let ApiCityTo = '';
      let ApiStateTo = '';
      let ApiZipTo = '';
      let ApiZipToCountryCode = '';
      if (
        paymentTypeValue === 'Inbound Collect' &&
        // || paymentTypeValue === 'Receiver'
        !shipToFinalAddress.City
      ) {
        OutbondReciever = shipToFinalAddress?.split(' ');

        ApiCityTo =
          paymentTypeValue === 'Inbound Collect'
            ? // || paymentTypeValue === 'Receiver'
              OutbondReciever[0]
            : shipToFinalAddress.City;
        ApiStateTo =
          paymentTypeValue === 'Inbound Collect'
            ? // || paymentTypeValue === 'Receiver'
              OutbondReciever[1]
            : shipToFinalAddress.State;
        ApiZipTo =
          paymentTypeValue === 'Inbound Collect'
            ? // ||paymentTypeValue === 'Receiver'
              OutbondReciever[2]
            : shipToFinalAddress.ZipCode;
        ApiZipToCountryCode =
          paymentTypeValue === 'Inbound Collect'
            ? // || paymentTypeValue === 'Receiver'
              OutbondReciever[3]
            : shipToFinalAddress.CountryCode;
      } else {
        ApiCityTo = shipToFinalAddress.City;
        ApiStateTo = shipToFinalAddress.State;
        ApiZipTo = shipToFinalAddress.ZipCode;
        ApiZipToCountryCode = shipToFinalAddress.CountryCode;
      }

      let TLquoteObj = {
        id: 0,
        carrierId: 0,
        carrierName: '',
        carrierLogo: '',
        invoiceType: '',
        shipmentId: '',
        proNumber: '',
        orderDate: shipmentDate,
        pickupDate: shipmentDate,
        invoiceNumber: '',
        deliveryDate: deliveryDate,
        invoiceDate: '',
        originCompany: '',
        originAddress:
          ApiCityFrom +
          ' ' +
          ApiStateFrom +
          ' ' +
          ApiZipFrom +
          ' ' +
          ApiZipFromCountryCode,
        originCity: ApiCityFrom,
        originState: ApiStateFrom,
        originZip: ApiZipFrom,
        originCountry: ApiZipFromCountryCode,
        destinationCompany: '',
        destinationAddress:
          ApiCityTo +
          ' ' +
          ApiStateTo +
          ' ' +
          ApiZipTo +
          ' ' +
          ApiZipToCountryCode,
        destinationCity: ApiCityTo,
        destinationState: ApiStateTo,
        destinationZip: ApiZipTo,
        destinationCountry: ApiZipToCountryCode,
        billToCompany: '',
        billToAddress: '',
        billToCity: '',
        billToState: '',
        billToZip: '',
        billToCountry: '',
        shipmentType: 'TL',
        equipmentType: newAddedDimensionsRow[0]?.UnitTypeValue,
        soNumber: '',
        clientPONumber: '',
        details: [
          {
            grossWeight: grossWeight,
            netWeight: newAddedDimensionsRow[0]?.WeightLBS,
            grossCharge: 0,
            discount: 0,
            netCharge: 0,
            itemNumber: '',
            ItemText: '',
            units: 1,
            pieces: 1,
            NMFCClass: newAddedDimensionsRow[0]?.Class,
            FreightClass: newAddedDimensionsRow[0]?.Class,
            nmfc: '',
            packagingType: newAddedDimensionsRow[0]?.UnitTypeValue,
            length: 0,
            width: 0,
            height: 0
          }
        ],
        pickupNumber: '',
        shipmentStatus: 'Saved',
        cargoValue: '',
        freightTerms: paymentTypeValue.replace(/\s+/g, ''),
        paymentTerms: paymentTypeValue,
        bolNumber: '',
        customerId: '',
        shippingNotes: '',
        originContactPerson: '',
        originContactEmail: '',
        originContactPhone: '',
        destinationContactPerson: '',
        destinationContactEmail: '',
        destinationContactPhone: '',
        billToContactPerson: '',
        billToContactEmail: '',
        billToContactPhone: '',
        locationName: selectedLocation?.location,
        location: shipLocationValue,
        locationId: shipLocationID,
        pickupStartTime: '',
        pickupEndTime: '',
        deliverStartTime: '',
        deliverEndTime: '',
        quoteId: 0,
        shipmentCharges: 0,
        actualPickupDate: shipmentDate,
        actualDeliveryDate: deliveryDate,
        sessionId: '',
        quoteNumber: '',
        dispatchInput1: '',
        lastUpdatedOn: '',
        serviceType: '',
        totalNetWeight: newAddedDimensionsRow[0].WeightLBS,
        totalGrossWeight: grossWeight ? grossWeight : 0,
        originTerminalAddress: '',
        originTerminalCity: '',
        originTerminalState: '',
        originTerminalPostalCode: '',
        originTerminalCountry: '',
        originTerminalContact: '',
        originTerminalContantEmail: '',
        originTerminalContactPhone: '',
        destinationTerminalAddress: '',
        destinationTerminalCity: '',
        destinationTerminalState: '',
        destinationTerminalPostalCode: '',
        destinationTerminalCountry: '',
        destinationTerminalContact: '',
        destinationTerminalContantEmail: '',
        destinationTerminalContactPhone: '',
        pickupOptions: {
          inside: false,
          liftGateServices: false,
          residential: false,
          secureAccess: false,
          limitedAccess: false,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: false,
          overLengthValue: 0
        },
        deliveryOptions: {
          inside: false,
          liftGateServices: false,
          residential: false,
          secureAccess: false,
          limitedAccess: false
        },
        pickupOptionsAccessorial: '',
        deliveryOptionAccessorial: '',
        carrierBillToCompany: '',
        carrierBillToAddress: '',
        carrierBillToCity: '',
        carrierBillToState: '',
        carrierBillToZip: '',
        carrierBillToCountry: '',
        carrierBillToContactPerson: '',
        carrierBillToContactEmail: '',
        carrierBillToContactPhone: '',
        surcharges: '',
        surchargesList: '',
        estimatedTransitDays: 0,
        OriginData: '',
        destinationData: '',
        salesOrderNumber: '',
        purchaseOrderNumber: '',
        freightInvoiceNumber: ''
      };
      let obj = {
        shipmentType: shipTypeValue,
        locationFull: selectedLocation.location,
        location: shipLocationValue,
        paymentType:
          paymentTypeValue === 'Outbound Prepaid'
            ? // ||
              // paymentTypeValue === 'Sender'
              '0'
            : paymentTypeValue === 'Inbound Collect'
            ? // ||
              //   paymentTypeValue === 'Receiver'
              '1'
            : '2',
        shipFrom: {
          city: ApiCityFrom,
          stateOrProvinceCode: ApiStateFrom,
          postalCode: ApiZipFrom,
          countryCode: ApiZipFromCountryCode,
          residential: true,
          streetLines: ApiCityFrom + ' ' + ApiStateFrom + ' ' + ApiZipFrom
        },
        shipTo: {
          city: ApiCityTo,
          stateOrProvinceCode: ApiStateTo,
          postalCode: ApiZipTo,
          countryCode: ApiZipToCountryCode,
          residential: true,
          streetLines: ApiCityTo + ' ' + ApiStateTo + ' ' + ApiZipTo
        },
        shipmentDate: new Date(),
        isHazmat: hazmatSwitch,
        commodityInfo: handlePushAddedCommodityInfo(),
        // commodityInfo: [
        //   {
        //     freightClass: newAddedDimensionsRow[0].Class
        //       ? newAddedDimensionsRow[0].Class
        //       : '50',
        //     packaging: newAddedDimensionsRow[0].UnitTypeValue
        //       ? newAddedDimensionsRow[0].UnitTypeValue
        //       : 'Pallet',
        //     pieces: newAddedDimensionsRow[0].NmbOfUnits
        //       ? newAddedDimensionsRow[0].NmbOfUnits
        //       : 0,
        //     weight: {
        //       units: 'lbs',
        //       value: newAddedDimensionsRow[0].WeightLBS
        //     },
        //     nmfc: newAddedClassRow[0].NMFCnumber
        //       ? newAddedClassRow[0].NMFCnumber
        //       : '',
        //     dimensions: {
        //       length: newAddedDimensionsRow[0].Length
        //         ? newAddedDimensionsRow[0].Length
        //         : 0,
        //       width: newAddedDimensionsRow[0].Width
        //         ? newAddedDimensionsRow[0].Width
        //         : 0,
        //       height: newAddedDimensionsRow[0].Height
        //         ? newAddedDimensionsRow[0].Height
        //         : 0,
        //       dimensionsUom: 'inches',
        //       isStackable: newAddedDimensionsRow[0].StackAble
        //     }
        //   }
        // ],
        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch,
          freezeProtection: false,
          overLength: false,
          overLengthValue: 0,
          callBeforeDelivery: callBeforeDeliveryServicesSwitch
        },
        codAmount: CODAmount ? parseInt(CODAmount) : 0,
        declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
        agencyId: AgencyID ? AgencyID : 0,
        isGuaranteed: false,
        locationId: shipLocationID
      };

      //   dispatch(setPickUpCheckedRedux(pickUpChecked));
      //   dispatch(setDeliveryCheckedRedux(deliveryChecked));
      //   dispatch(setRateValues(obj));

      let deepClone = JSON.parse(
        JSON.stringify(shipTypeValue === 'TL' ? TLquoteObj : obj)
      );
      // let newObj = { ...obj, };
      // deepClone.shipFrom.countryCode = 'US';
      // deepClone.shipTo.countryCode = 'US';
      validateAutocompletes(
        shipTypeValue === 'TL' ? TLquoteObj : obj,
        deepClone
      );
    }
  };

  const handlePickUpServicesChange = switchName => {
    if (switchName === 'hazmat') {
      setHazmatSwitch(c => !c);
    } else if (switchName === 'Stackable') {
      setStackableSwitch(c => !c);
    } else if (switchName === 'Inside Pickup') {
      setInsidePickupSwitch(c => !c);
    } else if (switchName === 'Inside Lift Gate Services') {
      setLiftgatePickupSwitch(c => !c);
    } else if (switchName === 'Residential Pickup') {
      setResidentialPickupSwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesPickupSwitch(c => !c);
    } else if (switchName === 'Freeze Protection') {
      setFreezeProtectionPickupSwitch(c => !c);
    } else if (switchName === 'Overlength') {
      setOverlengthPickupSwitch(c => !c);
      setIsOverLength(!isOverLength);
      setOverLengthValue('');
    } else setLimitedPickupAccessSwitch(c => !c);
  };

  const handleDeliveryServicesChange = switchName => {
    if (switchName === 'Inside Delivery') {
      setInsideDeliverySwitch(c => !c);
    } else if (switchName === 'Liftgate Delivery') {
      setLiftgateDeliverySwitch(c => !c);
    } else if (switchName === 'Residential Delivery') {
      setResidentialDeliverySwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesDeliverySwitch(c => !c);
    } else if (switchName === 'Call Before Delivery') {
      setCallBeforeDeliveryServicesSwitch(c => !c);
    } else setLimitedDeliverySwitch(c => !c);
  };
  let TempApiNames = [];
  let TLTempApiNames = [];
  let ParcelTempApiNames = [];
  const handleGetCarrierNames = data => {
    const LtlCarrierArray = data.filter(
      items => items.active && items.cType === 'LTL'
    );
    const TlCarrierArray = data.filter(
      items => items.active && items.cType === 'TL'
    );
    const ParcelCarrierArray = data.filter(
      items => items.active && items.cType === 'Parcel'
    );
    let TempApisEndPoint = [];

    for (let i = 0; i < LtlCarrierArray.length; i++) {
      const CarrierName = LtlCarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < LtlCarrierArray.length; i++) {
      const CarrierName = LtlCarrierArray[i].name;
      const ID = LtlCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === LtlCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + LtlCarrierArray[i].logo;
      TempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    TempApiNames.push({ title: 'Others', carrierID: '0', carrierLogo: '' });
    setCarrierApiNames(TempApiNames);
    for (let i = 0; i < TlCarrierArray.length; i++) {
      const CarrierName = TlCarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < TlCarrierArray.length; i++) {
      const CarrierName = TlCarrierArray[i].name;
      const ID = TlCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === LtlCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + TlCarrierArray[i].logo;
      TLTempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    TLTempApiNames.push({ title: 'Others', carrierID: '0', carrierLogo: '' });
    setTlCarrierApiNames(TLTempApiNames);
    for (let i = 0; i < ParcelCarrierArray.length; i++) {
      const CarrierName = ParcelCarrierArray[i]?.getRateEndPoint?.replace(
        '/',
        ''
      );
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < ParcelCarrierArray.length; i++) {
      const CarrierName = ParcelCarrierArray[i].name;
      const ID = ParcelCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === ParcelCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + ParcelCarrierArray[i].logo;
      ParcelTempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    ParcelTempApiNames.push({
      title: 'Others',
      carrierID: '0',
      carrierLogo: ''
    });
    setParcelCarrierApiNames(ParcelTempApiNames);
  };

  const checkPickupStatus = () => {
    if (pickUpChecked) {
      setInsidePickupSwitch(false);
      setLiftgatePickupSwitch(false);
      setResidentialPickupSwitch(false);
      setSecureAccesPickupSwitch(false);
      setFreezeProtectionPickupSwitch(false);
      setOverlengthPickupSwitch(false);
      setLimitedPickupAccessSwitch(false);
      setIsOverLength(false);

      //   dispatch(setRateValues(obj));
    }
  };
  const checkDeliveryStatus = () => {
    if (deliveryChecked) {
      setInsideDeliverySwitch(false);
      setLiftgateDeliverySwitch(false);
      setResidentialDeliverySwitch(false);
      setSecureAccesDeliverySwitch(false);
      setCallBeforeDeliveryServicesSwitch(false);
      setLimitedDeliverySwitch(false);

      //   dispatch(setRateValues(obj));
    }
  };
  const handlePickupServices = v => {
    setPickUpChecked(c => !c);
    checkPickupStatus();
    // setDeliveryChecked(false);
  };

  const handleDeliveryServices = () => {
    setDeliveryChecked(c => !c);
    checkDeliveryStatus();
    // setPickUpChecked(false);
  };

  const handleSetShipLocation = arr => {
    const temp = [];
    setUserLocationsArr(arr.assignedLocations);
    const array = arr.assignedLocations;
    for (let i = 0; i < array.length; i++) {
      const Element =
        array[i].name +
        ': ' +
        array[i].city +
        ' ' +
        array[i].stateOrProvinceCode +
        ' ' +
        array[i].postalCode +
        ' ' +
        array[i].countryCode;
      const LocationId = array[i].id;
      temp.push({ location: Element, id: LocationId });
    }
    setShipLocationLoader(false);
    setShipLocation(temp.reverse());
  };

  useEffect(() => {
    setShipLocationLoader(true);
    GetAagencyUserDetails(UserLoggedInEmail)
      .then(res => res.status === 200 && handleSetShipLocation(res.data.data))
      .catch(err => console.log(err));
    // GetLocations(AgencyID)
    //   .then(res => res.status === 200 && handleSetShipLocation(res.data.data))
    //   .catch(err => console.log(err));
  }, []);

  const handleCheckAddedRow = () => {};
  const handleKeyPress = event => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const handleDimensionsInputChange = (inputName, index, event) => {
    var filteredClass = [];
    var filteredUnitType = [];

    let totalWeight = 0;
    let TotalWeightSum;
    const maxLimit = 14999;
    const maxLimitParcel = 150;
    const values = [...newAddedDimensionsRow];
    if (inputName === 'WeightLBS') {
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      if (shipTypeValue === 'Parcel') {
        if (parseInt(inputValue, 10) > maxLimitParcel) {
          const updatedErrors = {
            ...validationErrors,
            newAddedDimensionsRow: 'Maximum weight limit is 150 lbs'
          };
          setValidationErrors(updatedErrors);
        } else {
          const updatedErrors = {
            ...validationErrors,
            newAddedDimensionsRow: null
          };
          setValidationErrors(updatedErrors);
          values[index].WeightLBS = inputValue;

          for (let index = 0; index < newAddedDimensionsRow.length; index++) {
            if (newAddedDimensionsRow[index].WeightLBS !== '') {
              totalWeight += parseInt(newAddedDimensionsRow[index].WeightLBS);
            }
          }
          setTotalLBSWeightDimensions(totalWeight);
          TotalWeightSum = totalLBSWeightClass
            ? parseInt(totalLBSWeightClass) + totalWeight
            : totalWeight;
          setTotalDimensionsWeightSum(totalWeight);
          handlePushAddedCommodityInfo(totalWeight);
        }
      } else if (
        shipTypeValue === 'LTL' &&
        parseInt(inputValue, 10) > maxLimit
      ) {
        const updatedErrors = {
          ...validationErrors,
          newAddedDimensionsRow: 'Maximum weight limit is 14999'
        };
        setValidationErrors(updatedErrors);
      } else {
        const updatedErrors = {
          ...validationErrors,
          newAddedDimensionsRow: null
        };
        setValidationErrors(updatedErrors);
        values[index].WeightLBS = inputValue;

        for (let index = 0; index < newAddedDimensionsRow.length; index++) {
          if (newAddedDimensionsRow[index].WeightLBS !== '') {
            totalWeight += parseInt(newAddedDimensionsRow[index].WeightLBS);
          }
        }
        setTotalLBSWeightDimensions(totalWeight);
        TotalWeightSum = totalLBSWeightClass
          ? parseInt(totalLBSWeightClass) + totalWeight
          : totalWeight;
        setTotalDimensionsWeightSum(totalWeight);
        handlePushAddedCommodityInfo(totalWeight, 'totalWeight');
      }
    } else if (inputName === 'Class') {
      if (event !== null) {
        filteredClass = ClassArray.filter(option =>
          option.toLowerCase().includes(event?.toLowerCase())
        );
      }
      setFreightClassHoveredValue(filteredClass[0]);
      // values[index].Class = event;
      if (!values[index]) {
        // If values[index] is undefined, create a new object
        values[index] = {};
      }

      values[index].Class = event;
    } else if (inputName === 'NmbOfUnits') {
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      handlePushAddedCommodityInfo(inputValue, 'NmbOfUnits');
      // if (parseInt(inputValue, 10) >= 0) {
      //   values[index].NmbOfUnits = inputValue;
      // }
      if (!isNaN(inputValue) && parseFloat(inputValue) > 0) {
        values[index].NmbOfUnits = inputValue;
        const updatedErrors = {
          ...validationErrors,
          numberOfUnits: null
        };
        setValidationErrors(updatedErrors);
      } else {
        values[index].NmbOfUnits = '';
      }
    } else if (inputName === 'Pieces') {
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      handlePushAddedCommodityInfo(inputValue, 'Pieces');
      // if (parseInt(inputValue, 10) >= 0) {
      //   values[index].NmbOfUnits = inputValue;
      // }
      if (!isNaN(inputValue) && parseFloat(inputValue) > 0) {
        values[index].Pieces = inputValue;
        const updatedErrors = {
          ...validationErrors,
          Pieces: null
        };
        setValidationErrors(updatedErrors);
      } else {
        values[index].Pieces = '';
      }
    } else if (inputName === 'UnitType') {
      if (event !== null) {
        filteredUnitType =
          shipTypeValue === 'LTL'
            ? LTLUnitTypeArr.filter(option =>
                option.toLowerCase().includes(event?.toLowerCase())
              )
            : ParcelUnitTypeArr.filter(option =>
                option.toLowerCase().includes(event?.toLowerCase())
              );
      }
      setUnitTypeHoveredValue(filteredUnitType[0]);
      // setUnitTypeName('');
      // values[index].UnitTypeValue = event;
      if (!values[index]) {
        // If values[index] is undefined, create a new object
        values[index] = {};
      }

      setUnitTypeName('');
      values[index].UnitTypeValue = event;
      handlePushAddedCommodityInfo(event, 'UnitType');
    } else if (inputName === 'Stackable') {
      values[index].StackAble = event;
      handlePushAddedCommodityInfo(event, 'Stackable');
    } else if (inputName === 'Length') {
      values[index].Length = event.target.value;
      handlePushAddedCommodityInfo(event.target.value, 'Length');

      const updatedErrors = {
        ...validationErrors,
        PlengthError: null
      };
      setValidationErrors(updatedErrors);
    } else if (inputName === 'Width') {
      handlePushAddedCommodityInfo(event.target.value, 'Width');

      values[index].Width = event.target.value;
      const updatedErrors = {
        ...validationErrors,
        PwidthError: null
      };
      setValidationErrors(updatedErrors);
    } else if (inputName === 'Vehicle') {
      handlePushAddedCommodityInfo(event.target.value, 'Width');

      values[index].Vehicle = event.target.value;
      const updatedErrors = {
        ...validationErrors,
        VehicleError: null
      };
      setValidationErrors(updatedErrors);
    } else {
      handlePushAddedCommodityInfo(event.target.value, 'Height');

      values[index].Height = event.target.value;
      const updatedErrors = {
        ...validationErrors,
        PheightError: null
      };
      setValidationErrors(updatedErrors);
    }
    setNewAddedDimensionsRow(values);
  };

  const handlePushAddedCommodityInfo = (removedIndex, valueName) => {
    let CommodityInfoApi = [];
    if (valueName === 'totalWeightMinus') {
      const updatedRows = finalCommodityInfoApi.filter(
        (row, index) => index !== removedIndex
      );
      setFinalCommodityInfoApi(updatedRows);
    } else {
      for (let i = 0; i < newAddedDimensionsRow.length; i++) {
        CommodityInfoApi.push({
          freightClass: newAddedDimensionsRow[i].Class,
          packaging: newAddedDimensionsRow[i].UnitTypeValue,
          units: newAddedDimensionsRow[i].NmbOfUnits,
          pieces: newAddedDimensionsRow[i].Pieces,
          weight: {
            units: 'lbs',
            value: newAddedDimensionsRow[i].WeightLBS
              ? newAddedDimensionsRow[i].WeightLBS
              : 0
            // valueName === 'totalWeight' ? totalWeight : totalDimensionsWeightSum
          },
          nmfc: newAddedClassRow[0].NMFCnumber,
          dimensions: {
            length: newAddedDimensionsRow[i].Length
              ? newAddedDimensionsRow[i].Length
              : shipTypeValue === 'Parcel'
              ? 15
              : 0,
            width: newAddedDimensionsRow[i].Width
              ? newAddedDimensionsRow[i].Width
              : shipTypeValue === 'Parcel'
              ? 11
              : 0,
            height: newAddedDimensionsRow[i].Height
              ? newAddedDimensionsRow[i].Height
              : shipTypeValue === 'Parcel'
              ? 14
              : 0,
            dimensionsUom: 'inches',
            isStackable: newAddedDimensionsRow[i].StackAble
          }
        });
      }
      // let CommodityInfoApi = [
      //   {
      // freightClass: newAddedDimensionsRow[0].Class,
      // packaging: newAddedDimensionsRow[0].UnitTypeValue,
      // pieces: newAddedDimensionsRow[0].NmbOfUnits,
      // weight: {
      //   units: 'lbs',
      //   value: TotalWeightSum
      // },
      // nmfc: newAddedClassRow[0].NMFCnumber,
      // dimensions: {
      //   length: newAddedDimensionsRow[0].Length,
      //   width: newAddedDimensionsRow[0].Width,
      //   height: newAddedDimensionsRow[0].Height,
      //   dimensionsUom: 'inches',
      //   isStackable: newAddedDimensionsRow[0].StackAble
      // }
      //   }
      // ];
      setFinalCommodityInfoApi(CommodityInfoApi);
      return CommodityInfoApi;
    }
  };
  // const handleClassInputChange = (inputName, index, event) => {
  //   let totalWeight = 0;
  //   let TotalWeightSum;
  //   const values = [...newAddedClassRow];
  //   if (inputName === 'WeightLBS') {
  //     const updatedErrors = { ...validationErrors, weightLBS: null };
  //     setValidationErrors(updatedErrors);

  //     values[index].WeightLBS = event.target.value;

  //     for (let index = 0; index < newAddedClassRow.length; index++) {
  //       if (newAddedClassRow[index].WeightLBS !== '') {
  //         totalWeight += parseInt(newAddedClassRow[index].WeightLBS);
  //       }
  //     }
  //     setTotalLBSWeightClass(totalWeight);
  //     TotalWeightSum = totalLBSWeightDimensions
  //       ? parseInt(totalLBSWeightDimensions) + totalWeight
  //       : totalWeight;
  //     setTotalClassWeightSum(totalWeight);
  //     handlePushAddedCommodityInfo(totalWeight);
  //   } else if (inputName === 'NMFCnumber') {
  //     values[index].NMFCnumber = event.target.value;
  //   } else if (inputName === 'Class') {
  //     setFreightClass(event);
  //     values[index].Class = event === null ? ClassArray[0] : event;
  //   }

  //   setNewAddedClassRow(values);
  // };

  const handleAddNewRow = e => {
    const temp = [];
    if (e === 'class') {
      const values = [...newAddedClassRow];
      values.push({ WeightLBS: '', Class: ClassArray[0], NMFCnumber: '' });
      setNewAddedClassRow(values);
    } else {
      const values = [...newAddedDimensionsRow];
      values.push({
        Class: '50',
        WeightLBS: '',
        UnitTypeValue:
          shipTypeValue === 'LTL' ? LTLUnitTypeArr[0] : LTLUnitTypeArr[2],
        NmbOfUnits: '1',
        Pieces: '1',
        StackAble: false,
        Length: shipTypeValue === 'Parcel' ? '15' : '',
        Width: shipTypeValue === 'Parcel' ? '11' : '',
        Height: shipTypeValue === 'Parcel' ? '14' : ''
      });
      setNewAddedDimensionsRow(values);
    }
  };

  const handleSubtractNewRow = (e, removedIndex) => {
    if (e === 'class') {
      const values = [...newAddedClassRow];
      values.splice(removedIndex, 1);
      // finalCommodityInfoApi.splice(removedIndex, 1);
      setNewAddedClassRow(values);
      let totalWeightLBS = parseInt(newAddedClassRow[removedIndex].WeightLBS);
      let minus;
      if (isNaN(totalWeightLBS)) {
        minus = parseInt(totalDimensionsWeightSum) - 0;
      } else {
        minus = parseInt(totalDimensionsWeightSum) - totalWeightLBS;
      }
      setTotalClassWeightSum(minus);
      handlePushAddedCommodityInfo(removedIndex, 'totalWeightMinus');

      // setTotalDimensionsWeightSum(totalDimensionsWeightSum - totalWeightLBS);
      let TotalWeightSum = totalDimensionsWeightSum - totalWeightLBS;
    } else {
      const values = [...newAddedDimensionsRow];
      values.splice(removedIndex, 1);
      // finalCommodityInfoApi.splice(removedIndex, 1);
      setNewAddedDimensionsRow(values);
      let totalWeightLBS = parseInt(
        newAddedDimensionsRow[removedIndex].WeightLBS
      );
      let minus;
      if (isNaN(totalWeightLBS)) {
        minus = parseInt(totalDimensionsWeightSum) - 0;
      } else {
        minus = parseInt(totalDimensionsWeightSum) - totalWeightLBS;
      }
      // totalWeightLBS = totalLBSWeightDimensions - totalLBSWeightDimensions;
      // minus = totalDimensionsWeightSum - totalWeightLBS;
      setTotalDimensionsWeightSum(minus);
      handlePushAddedCommodityInfo(removedIndex, 'totalWeightMinus');

      setTotalLBSWeightDimensions(totalWeightLBS);
    }
  };
  useEffect(() => {
    handleCheckAddedRow();
  }, [newAddedClassRow]);

  const switchStyle = {
    borderRadius: 2,
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#d81b60'
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#d81b60'
    },
    '&:hover .MuiSwitch-switchBase': {
      // color: 'brown'
    }
  };

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }
  const [shipFromOpen, setShipFromOpen] = useState(false);
  // const [open, setOpen] = useState(false);
  const [shipToOpen, setShipToOpen] = useState(false);

  const [options, setOptions] = useState([]);
  const [shipFromFinalAddress, setShipFromFinalAddress] = useState(
    paymentTypeValue === 'Outbound Prepaid'
      ? // || paymentTypeValue === 'Sender'
        shipLocationValue
      : ''
  );
  const [shipFromFinalValue, setShipFromFinalValue] = useState(
    !ShipFrom.includes('undefined') ? ShipFrom : ''
  );
  const [shipToFinalValue, setShipToFinalValue] = useState(
    ShipTo.includes('undefined') ? '' : ShipTo
  );
  const [shipFromErr, setShipFromErr] = useState(false);
  const [shipToFinalAddress, setShipToFinalAddress] = useState(
    paymentTypeValue === 'Inbound Collect' || paymentTypeValue === 'Receiver'
      ? shipLocationValue
      : ''
  );
  const [shipToErr, setShipToErr] = useState(false);

  const ShipFromloading = shipFromOpen && options.length === 0;
  const ShipToLoading = shipToOpen && options.length === 0;
  const [locationKeys, setLocationKeys] = useState([]);
  // const history = useHistory()
  const spinnerStyles = {
    '-webkit-appearance': 'none',
    backgroundColor: '#000', // Set the background color
    background:
      '#000 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAcCAYAAADr9QYhAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAI6ADAAQAAAABAAAAHAAAAACbzWzwAAABB0lEQVRIDe2XMQ6DMAxFf6suwAjszLDCTeASHALEyFlg5hLsXIJDtPIQVFkkgrhDVCWLcQzJ84/liEeSJG84MIqiwMMVmCAI8HRAlAPBwxxSsIf/VKZpGozjiCiKWL7X3Z8oQyB1XSPLMnRdZw0khlEgKn8JkAiGg0iBrJse1UZZlmr/U7vvO7ZtO43xSWp61jB8ManvO7BJQVEBmxa2iXkYnWpOKfPSUV6Zb9sWaZpqX12WBeu6auM8IOozBNL3/SnQNE2Y55nvp/XFfYY67DAMIPs97oKob8U1w4FsQQhIdEwqI7J0ZFVVgerEZvi7yaSauGZMi9+NOQMThqEbP3FxHCPPc3wAmdpEetL9b2QAAAAASUVORK5CYII=) no-repeat center center',
    filter: 'invert(100%)', // Invert the spinner color to white
    width: '1.7em',
    opacity: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    borderTopRightRadius: '0.25rem',
    borderBottomRightRadius: '0.25rem'
  };
  const onShipFromChange = (event, values) => {
    setShipFromloading(true);

    if (values !== null) {
      GetLocationsJSON(values)
        .then(
          res => res.status === 200 && handleUSAddresses(res.data, 'shipFrom')
        )
        .catch(err => {
          setShipFromloading(false);
          setShipToloading(false);
        });
    }
    let FinalObj = {};
    if (values !== null) {
      const updatedErrors = { ...validationErrors, shipFromErr: null };
      setValidationErrors(updatedErrors);
      setShipFromFinalValue(values);
      const tempData = values
        .split(' ')
        .map(item => item.trim())
        .filter(item => item !== '');
      if (tempData.length === 4) {
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        const CountryCode = tempData[3];
        FinalObj = {
          City,
          State,
          ZipCode,
          CountryCode
        };
      } else if (tempData.length === 6) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        const CountryCode = tempData[5];

        FinalObj = {
          City,
          State,
          ZipCode,
          CountryCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        const CountryCode = tempData[4];

        FinalObj = {
          City,
          State,
          ZipCode,
          CountryCode
        };
      }
      setShipFromFinalAddress(FinalObj);
    } else {
      // setShipFromFinalAddress('');
      // setShipFromFinalValue('');
      // setShipToFinalValue('');
    }
  };

  const onShipToChange = (event, values) => {
    setShipToloading(true);
    // var filtered = [];
    if (values !== null) {
      GetLocationsJSON(values)
        .then(
          res => res.status === 200 && handleUSAddresses(res.data, 'shipTo')
        )
        .catch(err => {
          setShipFromloading(false);
          console.log(err, 'Get Locations Error');
        });
      // filtered = shipCity.filter(option =>
      //   option?.toLowerCase().includes(values?.toLowerCase())
      // );
    }
    // setShipToHoveredValue(filtered[0]);
    let FinalObj2 = {};
    if (values !== null) {
      const updatedErrors = { ...validationErrors, shipToErr: null };
      setValidationErrors(updatedErrors);
      // setShipFromErr(false);
      setShipToFinalValue(values);
      const tempData = values
        .split(' ')
        .map(item => item.trim())
        .filter(item => item !== '');
      if (tempData.length === 4) {
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        const CountryCode = tempData[3];

        FinalObj2 = {
          City,
          State,
          ZipCode,
          CountryCode
        };
        // setShipToFinalAddress(FinalObj2);
      } else if (tempData.length === 6) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        const CountryCode = tempData[5];
        FinalObj2 = {
          City,
          State,
          ZipCode,
          CountryCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        const CountryCode = tempData[4];

        FinalObj2 = {
          City,
          State,
          ZipCode,
          CountryCode
        };

        // setShipToFinalAddress(FinalObj2);
      }
      setShipToFinalAddress(FinalObj2);
    } else {
      // setShipToFinalAddress('');
      // setShipToFinalValue('');
      // setShipFromErr(true);
    }
  };
  const filterOptions = createFilterOptions({
    limit: 1000
  });

  const validateAutocompletes = (obj, deepClone) => {
    const errors = {};
    if (!shipTypeValue) {
      errors['shipTypeErr'] = 'Please select ship type';
    }
    //  else if (!paymentTypeValue) {
    //   errors['paymentTypeErr'] = 'Please select payment type';
    // }
    else if (!shipLocationValue && paymentTypeValue !== 'Third Party') {
      errors['shipTypeLocation'] = 'Please select ship location';
      // if (selectLocations.current) {
      selectLocations.current.focus();
      // }
    } else if (
      (!shipFromFinalAddress || !shipFromFinalValue) &&
      paymentTypeValue !== 'Outbound Prepaid'
      // || paymentTypeValue === 'Sender'
    ) {
      errors['shipFromErr'] = 'Please select ship from';
      selectShipFrom.current.focus();
      setShipFromHoveredValue(null);
      setShipFromFinalValue('');
      setOpenShipFrom(false);
    } else if (
      (!shipToFinalAddress || !shipToFinalValue) &&
      paymentTypeValue !== 'Inbound Collect'
      // || paymentTypeValue === 'Receiver'
    ) {
      errors['shipToErr'] = 'Please select ship to';
      selectShipTo.current.focus();
      setShipToHoveredValue(null);
    }
    // if (!newAddedClassRow[0].WeightLBS) {
    //   errors['weightLBS'] = 'Please enter weight';
    // }
    else if (!newAddedDimensionsRow[0].WeightLBS) {
      errors['newAddedDimensionsRow'] = 'Please enter weight';
      enterWeight?.current?.focus();
    } else if (!newAddedDimensionsRow[0].NmbOfUnits) {
      errors['numberOfUnits'] = 'enter number of units';
      // enterWeight.current.focus();
    }
    // else if (shipTypeValue === 'Parcel' && !newAddedDimensionsRow[0].Length) {
    //   errors['PlengthError'] = 'enter length';
    // } else if (shipTypeValue === 'Parcel' && !newAddedDimensionsRow[0].Width) {
    //   errors['PwidthError'] = 'enter width';
    // } else if (shipTypeValue === 'Parcel' && !newAddedDimensionsRow[0].Height) {
    //   errors['PheightError'] = 'enter height';
    // }

    // if (ShipFrom) {
    //   const updatedErrors = { ...validationErrors, shipToErr: null };
    //   setValidationErrors(updatedErrors);
    // }
    // add more validations for other Autocomplete components
    setValidationErrors(errors);

    if (
      shipTypeValue &&
      paymentTypeValue &&
      shipFromFinalAddress &&
      shipFromFinalValue &&
      shipToFinalValue &&
      shipToFinalAddress &&
      newAddedDimensionsRow[0].WeightLBS &&
      newAddedDimensionsRow[0].NmbOfUnits
    ) {
      const CopyNewAddedClassRow = [...newAddedClassRow];

      dispatch(setQuickQuoteRateValues(obj));

      dispatch(setQuickQuoteRateLoc(selectedLocation));

      dispatch(setQuickQuotePickUpCheckedRedux(pickUpChecked));
      dispatch(setQuickQuoteDeliveryCheckedRedux(deliveryChecked));
      dispatch(setClassDimensionsButton(alignment));
      dispatch(setClassRows(newAddedClassRow));
      dispatch(setDimensionRows(newAddedDimensionsRow));
      dispatch(setClassWeight(totalClassWeightSum));
      dispatch(setDimensionsWeight(totalDimensionsWeightSum));

      if (shipTypeValue === 'TL') {
        history.push({
          pathname: '/bookshipmentTL',
          state: {
            pickupRowData: JSON.stringify(obj),
            disptachOpen: 'Get Quote',
            rateType: 'Quick Quote',
            carrierApiNames: ParcelCarrierApiNames,
            paymentType: obj.paymentType,
            locationId: shipLocationID,
            userLocationsArr: userLocationsArr
          }
        });
      } else {
        history.push({
          pathname: '/bookshipment',
          state: {
            pickupRowData: JSON.stringify(obj),
            disptachOpen: 'Get Quote',
            rateType: 'Quick Quote',
            carrierApiNames: carrierApiNames,
            paymentType: obj.paymentType,
            locationId: shipLocationID,
            userLocationsArr: userLocationsArr
          }
        });
      }
    }
  };

  const handleChangePaymentType = (event, newValue) => {
    setOpenShipFrom(false);
    setOpenShipTo(false);
    var filtered = [];
    if (!Array.isArray(newValue) && newValue !== null) {
      filtered =
        shipTypeValue === 'LTL'
          ? LTLPaymentTypeArr.filter(option =>
              option.toLowerCase().includes(newValue?.toLowerCase())
            )
          : parcelPaymentTypeArr.filter(option =>
              option.toLowerCase().includes(newValue?.toLowerCase())
            );
    }
    setPaymentTypeHoveredValue(filtered[0]);
    if (newValue === null) {
      setPaymentTypeValue(LTLPaymentTypeArr[0]);
    }
    if (newValue === 'Third Party') {
      setPaymentTypeInputChange(true);
      setShipFromFinalAddress('');
      setShipToFinalAddress('');
      setShipFromFinalValue('');
      setShipToFinalValue('');
      setPaymentTypeValue(newValue);
    } else if (
      newValue === 'Outbound Prepaid'
      // || newValue === 'Sender'
    ) {
      const updatedErrors = { ...validationErrors, shipFromErr: null };
      setValidationErrors(updatedErrors);
      setPaymentTypeInputChange(true);
      setShipFromFinalAddress(shipLocationValue);
      setPaymentTypeValue(newValue);
      setShipToFinalAddress('');
      setShipToFinalValue('');
    } else if (
      newValue === 'Inbound Collect'
      // || newValue === 'Receiver'
    ) {
      setPaymentTypeInputChange(true);
      setShipToFinalAddress(shipLocationValue);
      setPaymentTypeValue(newValue);
      setOpenShipFrom(false);
      setShipFromFinalAddress('');
      setShipFromFinalValue('');
    }
  };

  const [distanceMiles, setDistanceMiles] = useState(0);

  const PrettoSlider = styled(Slider)({
    // color: '#52af77',
    height: 6.5,
    '& .MuiSlider-track': {
      border: 'none'
    },
    '& .MuiSlider-thumb': {
      display: 'none',
      height: 10,
      width: 10
    },
    '& .MuiSlider-valueLabel': {
      display: 'none'
    }
  });

  useEffect(() => {
    let OutbondShipper;
    let OutbondReciever;
    let ApiZipFrom = '';
    let ApiZipTo = '';

    if (paymentTypeValue === 'Outbound Prepaid' && !shipFromFinalAddress.City) {
      OutbondShipper = shipFromFinalAddress?.split(' ');

      ApiZipFrom =
        paymentTypeValue === 'Outbound Prepaid'
          ? OutbondShipper[2]
          : shipFromFinalAddress.ZipCode;
    } else {
      ApiZipFrom = shipFromFinalAddress.ZipCode;
    }

    if (paymentTypeValue === 'Inbound Collect' && !shipToFinalAddress.City) {
      OutbondReciever = shipToFinalAddress?.split(' ');
      ApiZipTo =
        paymentTypeValue === 'Inbound Collect'
          ? // ||paymentTypeValue === 'Receiver'
            OutbondReciever[2]
          : shipToFinalAddress.ZipCode;
    } else {
      ApiZipTo = shipToFinalAddress.ZipCode;
    }
    setDistanceMiles(0);

    if (ApiZipFrom && ApiZipTo) {
      CalculateDistance(ApiZipFrom, ApiZipTo)
        .then(res => {
          setDistanceMiles(res?.data?.rows[0]?.elements[0]?.distance?.text);
        })
        .catch(err => console.log(err, 'Distance Api Error'));
      // axios
      //   .get(
      //     `https://a.maxease.net/get-location-distance/?fromZip=${ApiZipFrom}&toZip=${ApiZipTo}`
      //   )
      //   .then(res => setDistanceMiles(res?.data?.data?.route_distance_miles))
      //   .catch(err => console.log(err, 'Distance Api Error'));
    }
  }, [shipFromFinalAddress, shipToFinalAddress]);

  const handleShipLocationChange = (event, newValue) => {
    setOpenShipFrom(false);
    setOpenShipTo(false);

    var filtered = [];
    if (newValue !== null && !newValue.location && shipTypeValue) {
      filtered = shipLocation.filter(option =>
        option.location?.toLowerCase().includes(newValue?.toLowerCase())
      );
      setLocationInputChange(true);
    }
    setLocationHoveredValue(filtered[0]);
    // if (filtered[0]) {
    // setSelectedLocation(filtered[0]);
    // dispatch(setQuickQuoteRateLoc(filtered[0]));
    // }

    if (newValue?.location && newValue?.id !== shipLocationID) {
      const SplittedLocation = newValue.location.split(': ');
      setShipLocationValue(SplittedLocation[1]);
      setShipLocationID(newValue?.id);
      setSelectedLocation(newValue);
      dispatch(setQuickQuoteRateLoc(newValue));
      // handleLocationIdChange(newValue?.id)
      if (
        paymentTypeValue === 'Outbound Prepaid'
        //  || paymentTypeValue === 'Sender'
      ) {
        setShipFromFinalAddress(SplittedLocation[1]);
      }
      if (
        paymentTypeValue === 'Inbound Collect'
        // || paymentTypeValue === 'Receiver'
      ) {
        setShipToFinalAddress(SplittedLocation[1]);
      }
      // paymentTypeValue === 'Outbound Prepaid' ?
      //   setShipFromFinalAddress(SplittedLocation[1])
      //   : setShipToFinalAddress(SplittedLocation[1]);
      setValidationErrors({
        ...validationErrors,
        shipTypeLocation: null
      });
    } else {
      setLocationInputChange(true);
    }
  };
  const isOptionDisabled = option => {
    console.log('Checking if option is disabled:', option);
    if (
      option === 'TL' &&
      selectedOptions.some(val => val === 'LTL' || val === 'Parcel')
    ) {
      return true;
    }
    if (
      (option === 'LTL' || option === 'Parcel') &&
      selectedOptions.includes('TL')
    ) {
      return true;
    }
    return false;
  };

  const handleTlFlow = () => {
    setTotalDimensionsWeightSum('');
    setNewAddedDimensionsRow([
      {
        Class: '50',
        WeightLBS: '',
        UnitTypeValue: LTLUnitTypeArr[2],
        NmbOfUnits: '1',
        Pieces: '1',
        StackAble: false,
        Length: '',
        Width: '',
        Height: ''
      }
    ]);
  };
  const handleParcelFlow = () => {
    // handleDimensionsInputChange('UnitType', 0, 'Box');
    // handleChangePaymentType('event', 'Outbound Prepaid');
    setTotalDimensionsWeightSum('');
    setNewAddedDimensionsRow([
      {
        Class: '50',
        WeightLBS: '',
        UnitTypeValue: LTLUnitTypeArr[2],
        NmbOfUnits: '1',
        Pieces: '1',
        StackAble: false,
        Length: '15',
        Width: '11',
        Height: '14'
      }
    ]);
    // const updatedErrors = {
    //   ...validationErrors,
    //   newAddedDimensionsRow: null
    // };
    // setTimeout(() => {
    //   setValidationErrors(updatedErrors);
    // }, 50);
  };

  const handleLTLFlow = () => {
    // handleDimensionsInputChange('UnitType', 0, 'Pallet');
    // if (shipLocationValue) {
    setTotalDimensionsWeightSum('');
    // handleChangePaymentType('event', 'Outbound Prepaid');
    setNewAddedDimensionsRow([
      {
        Class: '50',
        WeightLBS: '',
        UnitTypeValue: LTLUnitTypeArr[0],
        NmbOfUnits: '1',
        Pieces: '1',
        StackAble: false,
        Length: '',
        Width: '',
        Height: ''
      }
    ]);
    // const updatedErrors = {
    //   ...validationErrors,
    //   newAddedDimensionsRow: null
    // };
    // setTimeout(() => {
    //   setValidationErrors(updatedErrors);
    // }, 50);
    // }
  };

  // const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <div
      style={
        layoutMode === 'dark'
          ? {
              backgroundColor: '#303841',
              display: 'flex',
              justifyContent: 'center',
              padding: '10 0 10 0'
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              padding: '10 0 10 0'
            }
      }
    >
      <MetaTags>
        {/* <title>TMS | Quick Quote</title> */}
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Quick Quote | Shiptechpro'
            : 'Quick Quote | TMS'}
        </title>
      </MetaTags>
      <div>
        <Grid container spacing={0} justifyContent='center'>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent='center'
          >
            <Paper style={{ borderRadius: 5 }}>
              <Card
                sx={
                  isMobile
                    ? { width: '90vw' }
                    : {
                        width: '65vw'
                      }
                }
              >
                <CardContent
                  sx={
                    isMobile
                      ? { overflowY: 'scroll', position: 'relative' }
                      : newAddedDimensionsRow.length >= 3
                      ? {
                          height: '30vw',
                          overflowY: 'scroll',
                          position: 'relative'
                        }
                      : {
                          height: '25vw',
                          overflowY: 'hidden',
                          position: 'relative'
                        }
                  }
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <Autocomplete
                        sx={{ m: 0, p: 0 }}
                        multiple
                        fullWidth
                        size='small'
                        // renderOption={(props, option) => (
                        //   <Box style={{ fontSize: 12, m: 0, p: 0 }} {...props}>
                        //     {option}
                        //   </Box>
                        // )}
                        options={ShipTypeArr}
                        value={selectedOptions}
                        onChange={(event, newValue) => {
                          setValidationErrors({
                            ...validationErrors,
                            shipTypeErr: null
                          });
                          let temArr = [];
                          temArr.push(...newValue);
                          if (
                            temArr.find(
                              item =>
                                item === 'TL' && temArr.indexOf('TL') !== 0
                            )
                          ) {
                            setSelectedOptions(['TL']);
                            setShipTypeValue('TL');
                            handleTlFlow();
                          } else {
                            setSelectedOptions(
                              newValue.filter(item => item !== 'TL')
                            );
                            setShipTypeValue(
                              newValue.filter(item => item !== 'TL').join('')
                            );

                            if (
                              newValue.includes('LTL') ||
                              newValue.length === 0
                            ) {
                              handleLTLFlow();
                            } else {
                              handleParcelFlow();
                            }
                          }
                          if (newValue.length === 0) {
                            setSelectedOptions(['LTL']);
                            setShipTypeValue(['LTL']);
                          }
                        }}
                        // isOptionDisabled={option => isOptionDisabled(option)}
                        renderOption={(props, option) => {
                          // const disabled = isOptionDisabled(option);
                          return (
                            <li
                              {...props}
                              style={{
                                fontSize: 13
                                // opacity: disabled ? 0.5 : 1,
                                // pointerEvents: disabled ? 'none' : 'auto'
                              }}
                            >
                              {option}
                            </li>
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(validationErrors['shipTypeErr'])}
                            helperText={validationErrors['shipTypeErr']}
                            // placeholder='Ship type'
                            variant='standard'
                            sx={{
                              '& .MuiChip-root': {
                                fontSize: '13px', // Adjust font size here
                                height: '18px' // Optionally adjust chip height
                              }
                            }}
                            InputProps={{
                              style: { fontSize: 12, m: 0, p: 0 },
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <InputAdornment position='start'>
                                    <MergeTypeIcon />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Autocomplete
                        fullWidth
                        onHighlightChange={(event, option) => {
                          !locationInputChange &&
                            setLocationHoveredValue(option);
                        }}
                        // onBlur={() => {
                        //   setLocationInputChange(false);

                        //   if (locationHoveredValue) {
                        //     handleShipLocationChange(null, locationHoveredValue);
                        //   }
                        // }}
                        renderOption={(props, option, state) => (
                          <Box
                            key={state.index}
                            style={{ fontSize: 13 }}
                            {...props}
                          >
                            <b>{option?.location.split(': ')[0] + ': '}</b>{' '}
                            &nbsp;
                            {option?.location.split(': ')[1]}
                          </Box>
                        )}
                        filterOptions={(options, state) =>
                          options.filter(option =>
                            option?.location
                              .split(': ')[1]
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          )
                        }
                        openOnFocus
                        size='small'
                        value={shipLocationValue}
                        options={shipLocation}
                        getOptionLabel={option => {
                          if (shipLocationValue === option) {
                            return option;
                          } else {
                            return shipLocationValue;
                          }
                        }}
                        onChange={(event, newValue) => {
                          setLocationInputChange(false);
                          handleShipLocationChange(event, newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          handleShipLocationChange(event, newValue);
                        }}
                        loading={shipLocationLoader}
                        // sx={{ marginLeft: 1 }}
                        renderInput={params => (
                          <TextField
                            onKeyDown={e => {
                              if (e.key === 'Tab' && locationHoveredValue) {
                                handleShipLocationChange(
                                  e,
                                  locationHoveredValue
                                );
                                setOpenShipFrom(false);
                                setOpenShipTo(false);
                              }
                            }}
                            inputRef={selectLocations}
                            error={Boolean(
                              validationErrors['shipTypeLocation']
                            )}
                            helperText={validationErrors['shipTypeLocation']}
                            // sx={{ width: 290 }}
                            placeholder='Select Locations'
                            variant='standard'
                            {...params}
                            InputProps={{
                              style: { fontSize: 12 },
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <LocationIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Autocomplete
                        fullWidth
                        // onHighlightChange={(event, option) => {
                        //   !paymentTypeInputChange &&
                        //     setPaymentTypeHoveredValue(option);
                        // }}
                        onBlur={() => {
                          setPaymentTypeInputChange(false);
                          if (paymentTypeHoveredValue) {
                            handleChangePaymentType(
                              null,
                              paymentTypeHoveredValue
                            );
                          }
                        }}
                        openOnFocus
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option}
                          </Box>
                        )}
                        size='small'
                        options={
                          // shipTypeValue === 'LTL'
                          //   ?
                          LTLPaymentTypeArr
                          // : parcelPaymentTypeArr
                        }
                        disabled={!shipLocationValue ? true : false}
                        value={
                          // SelectedRates?.paymentType
                          //   ? SelectedRates?.paymentType
                          // :
                          paymentTypeValue
                        }
                        onChange={(event, newValue) => {
                          setPaymentTypeInputChange(false);
                          handleChangePaymentType(event, newValue);
                        }}
                        onInputChange={(event, newValue) =>
                          handleChangePaymentType(event, newValue)
                        }
                        // sx={{ marginLeft: 1 }}
                        renderInput={params => (
                          <TextField
                            onKeyDown={e => {
                              if (e.key === 'Tab' && paymentTypeHoveredValue) {
                                handleChangePaymentType(
                                  e,
                                  paymentTypeHoveredValue
                                );
                                setOpenShipFrom(false);
                                setOpenShipTo(false);
                              }
                            }}
                            error={Boolean(validationErrors['paymentTypeErr'])}
                            helperText={validationErrors['paymentTypeErr']}
                            // sx={{ width: 290 }}
                            placeholder='Payment Type'
                            variant='standard'
                            {...params}
                            InputProps={{
                              style: { fontSize: 12 },
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <PaymentIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ mt: 1 }} container spacing={0}>
                    <Label
                      style={{
                        margin: '8px 0px 1px 0px',
                        fontWeight: 700,
                        fontSize: 12
                      }}
                    >
                      Shipment Info
                    </Label>
                    <Grid sx={{ mt: 0 }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Autocomplete
                          fullWidth
                          noOptionsText={
                            shipCity.length !== 0 ? 'Loading...' : 'No Options'
                          }
                          open={openShipFrom}
                          onHighlightChange={(event, option) => {
                            !shipFromHoveredValue &&
                              setShipFromHoveredValue(option);
                          }}
                          onBlur={() => {
                            if (shipFromHoveredValue) {
                              onShipFromChange(null, shipFromHoveredValue);
                            }
                          }}
                          renderOption={(props, option, state) => (
                            <Box
                              style={{ fontSize: 13 }}
                              {...props}
                              key={state.index}
                            >
                              {option}
                            </Box>
                          )}
                          disabled={
                            paymentTypeValue === 'Outbound Prepaid'
                            // || paymentTypeValue === 'Sender'
                          }
                          filterOptions={filterOptions}
                          onFocus={event => {
                            setOpenShipTo(false);
                            event.stopPropagation();
                          }}
                          size='small'
                          options={shipCity}
                          value={
                            paymentTypeValue === 'Outbound Prepaid'
                              ? // || paymentTypeValue === 'Sender'
                                shipLocationValue
                              : shipFromFinalValue
                          }
                          onChange={(event, newValue) =>
                            onShipFromChange(event, newValue)
                          }
                          onInputChange={(event, newValue) => {
                            onShipFromChange(event, newValue);
                            shipLocationValue !== newValue &&
                              setOpenShipFrom(!!newValue);
                          }}
                          onClose={() => setOpenShipFrom(false)}
                          loading={shipFromloading}
                          renderInput={params => (
                            <TextField
                              {...params}
                              onKeyDown={e => {
                                if (e.key === 'Tab' && shipFromHoveredValue) {
                                  onShipFromChange(e, shipFromHoveredValue);
                                }
                              }}
                              slotProps={{
                                input: {
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {shipFromloading ? (
                                        <CircularProgress
                                          color='inherit'
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }
                              }}
                              inputRef={selectShipFrom}
                              error={Boolean(validationErrors['shipFromErr'])}
                              helperText={validationErrors['shipFromErr']}
                              label='Ship From'
                              disableClearable
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              InputProps={{
                                style: {
                                  fontSize: 12
                                },
                                ...params.InputProps
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Autocomplete
                          noOptionsText={
                            shipCity.length !== 0 ? 'Loading...' : 'No Options'
                          }
                          fullWidth
                          loading={shipToloading}
                          open={openShipTo}
                          onHighlightChange={(event, option) => {
                            !shipToHoveredValue &&
                              setShipToHoveredValue(option);
                          }}
                          onChange={(event, newValue) =>
                            onShipToChange(event, newValue)
                          }
                          onInputChange={(event, newValue) => {
                            onShipToChange(event, newValue);
                            shipLocationValue !== newValue &&
                              setOpenShipTo(!!newValue);
                          }}
                          onClose={() => setOpenShipTo(false)}
                          renderOption={(props, option) => (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option}
                            </Box>
                          )}
                          filterOptions={filterOptions}
                          disabled={
                            paymentTypeValue === 'Inbound Collect' ||
                            paymentTypeValue === 'Receiver'
                              ? true
                              : false
                          }
                          size='small'
                          // sx={{ width: 290 }}
                          options={shipCity}
                          value={
                            paymentTypeValue === 'Inbound Collect' ||
                            paymentTypeValue === 'Receiver'
                              ? shipLocationValue
                              : shipToFinalValue
                          }
                          onFocus={event => {
                            setOpenShipFrom(false);
                            event.stopPropagation();
                          }}
                          // onChange={onShipToChange}
                          renderInput={params => (
                            <TextField
                              onKeyDown={e => {
                                if (e.key === 'Tab' && shipToHoveredValue) {
                                  // e.preventDefault(); // prevent the default Tab behavior
                                  onShipToChange(e, shipToHoveredValue);
                                }
                              }}
                              disableClearable
                              inputRef={selectShipTo}
                              // required={true}
                              {...params}
                              error={Boolean(validationErrors['shipToErr'])}
                              helperText={validationErrors['shipToErr']}
                              label='Ship To'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              InputProps={{
                                style: {
                                  fontSize: 12
                                },
                                ...params.InputProps
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Stack
                          spacing={-2}
                          direction='row'
                          sx={{
                            alignItems: 'start',
                            mb: 1,
                            position: 'relative'
                          }}
                        >
                          {/* <div style={{ width: '100%', position: 'relative', display: 'flex', spacing: -2 }}> */}
                          <LocationOnIcon color='info' sx={{ zIndex: 99 }} />
                          <PrettoSlider
                            aria-label='Distance-miles'
                            value={distanceMiles === 0 ? 0 : 40}
                            step={15}
                            marks
                          />
                          <LocalShippingIcon
                            color='info'
                            fontSize='large'
                            sx={{
                              position: 'absolute',
                              left: distanceMiles === 0 ? '10%' : '40%',
                              top: -15
                            }}
                          />
                          <Typography
                            style={{
                              position: 'absolute',
                              left: distanceMiles === 0 ? '10%' : '32%',
                              top: 20,
                              color: '#1976d2'
                            }}
                          >
                            {distanceMiles}
                          </Typography>
                          <WhereToVoteIcon color='info' sx={{ zIndex: 99 }} />
                        </Stack>

                        {/* <TextField
                          fullWidth
                          onChange={e => setShipmentDate(e.target.value)}
                          size='small'
                          // sx={
                          //   shipTypeValue === 'LTL'
                          //     ? { width: 170, marginRight: 1 }
                          //     : { width: 290 }
                          // }
                          disabled
                          id='outlined-password-input'
                          defaultValue={shipmentDate}
                          type='date'
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          inputProps={{
                            min: today,
                            style: {
                              fontSize: 12
                            }
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  {shipTypeValue === 'Tanker' ? (
                    <div>
                      <Label
                        style={{
                          margin: '20px 0px 20px 0px',
                          fontWeight: 700,
                          fontSize: 12
                        }}
                      >
                        Tanker / Equipment Requirements
                      </Label>
                    </div>
                  ) : (
                    <>
                      {/* <div style={{ marginTop: 5 }}>
                        Distance in Miles: {distanceMiles}
                      </div> */}
                      <div>
                        <Label
                          style={{
                            margin: '17px 0px 1px 0px',
                            fontWeight: 700,
                            fontSize: 12
                          }}
                        >
                          Commodity Info
                        </Label>
                      </div>
                    </>
                  )}

                  <>
                    {shipTypeValue === 'Tanker' ? (
                      <div style={{ height: '20vh' }}>
                        <ReactQuill
                          style={{ height: '15vh' }}
                          // value={pickupInstContent}
                          // onChange={handlePickupInstEditorChange}
                        />
                      </div>
                    ) : (
                      newAddedDimensionsRow.map((items, index, arr) => (
                        <Grid
                          container
                          sx={
                            {
                              // display: 'flex',
                              // justifyContent: 'center',
                              // alignItems: 'center'
                            }
                          }
                          spacing={2}
                        >
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label
                              style={{
                                margin: '10px 0px 6px 28px',
                                fontWeight: 700,
                                fontSize: 12,
                                whiteSpace: 'noWrap'
                              }}
                            >
                              {shipTypeValue === 'TL'
                                ? 'Net Weight (lbs) '
                                : 'Weight (lbs)'}
                            </Label>

                            <Grid
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              {index + 1 === arr.length && arr.length > 1 ? (
                                <RemoveCircleIcon
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={e =>
                                    handleSubtractNewRow('dimensions', index)
                                  }
                                />
                              ) : (
                                <AddCircleIcon
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={e => handleAddNewRow('dimensions')}
                                />
                              )}

                              <TextField
                                fullWidth
                                className={classes.inputRoot}
                                inputRef={enterWeight}
                                error={Boolean(
                                  validationErrors['newAddedDimensionsRow']
                                )}
                                helperText={
                                  validationErrors['newAddedDimensionsRow']
                                }
                                FormHelperTextProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    margin: '0 0 0 0'
                                  }
                                }}
                                value={
                                  items.WeightLBS === 0 ? '' : items.WeightLBS
                                }
                                onChange={e =>
                                  handleDimensionsInputChange(
                                    'WeightLBS',
                                    index,
                                    e
                                  )
                                }
                                size='small'
                                // sx={{ ml: 0.5 }}
                                // sx={
                                //   shipTypeValue === 'LTL'
                                //     ? { width: 100 }
                                //     : { width: 190 }
                                // }
                                id='outlined-weight'
                                type='number'
                                // autoComplete='current-password'
                                InputLabelProps={{
                                  style: { fontSize: 12 }
                                }}
                                InputProps={{
                                  // min: 0,
                                  style: {
                                    height: 32,
                                    fontSize: 12
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>

                          {(shipTypeValue === 'LTL' ||
                            selectedOptions === '') && (
                            <Grid item xs={12} sm={12} md={2} lg={1.5} xl={1.5}>
                              <Label
                                style={{
                                  margin: '10px 0px 6px 0px',
                                  fontWeight: 700,
                                  fontSize: 12,
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Freight Class
                              </Label>
                              <Autocomplete
                                fullWidth
                                // onHighlightChange={(event, option) => {
                                //   setFreightClassHoveredValue(option);
                                // }}
                                disableClearable
                                onBlur={() => {
                                  if (freightClassHoveredValue) {
                                    handleDimensionsInputChange(
                                      'Class',
                                      index,
                                      freightClassHoveredValue
                                    );
                                  }
                                }}
                                renderOption={(props, option) => (
                                  <Box style={{ fontSize: 13 }} {...props}>
                                    {option}
                                  </Box>
                                )}
                                name='Class'
                                size='small'
                                options={ClassArray}
                                // defaultValue={'50'}
                                value={items.Class}
                                onChange={(event, newValue) =>
                                  handleDimensionsInputChange(
                                    'Class',
                                    index,
                                    newValue
                                  )
                                }
                                onInputChange={(event, newValue) =>
                                  handleDimensionsInputChange(
                                    'Class',
                                    index,
                                    newValue
                                  )
                                }
                                // sx={{ marginLeft: 1 }}
                                renderInput={params => (
                                  <TextField
                                    onKeyDown={e => {
                                      if (
                                        e.key === 'Tab' &&
                                        freightClassHoveredValue
                                      ) {
                                        // e.preventDefault(); // prevent the default Tab behavior
                                        handleDimensionsInputChange(
                                          'Class',
                                          e,
                                          freightClassHoveredValue
                                        );
                                      }
                                    }}
                                    // sx={{ width: 90 }}
                                    // placeholder='Payment Type'
                                    variant='outlined'
                                    {...params}
                                    InputProps={{
                                      style: { fontSize: 12, height: 32 },
                                      ...params.InputProps
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          {shipTypeValue !== 'TL' ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={shipTypeValue === 'LTL' ? 1.5 : 2}
                                xl={shipTypeValue === 'LTL' ? 1.5 : 2}
                              >
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12
                                  }}
                                >
                                  Unit Type
                                </Label>
                                <Autocomplete
                                  // onHighlightChange={(event, option) => {
                                  //   setUnitTypeHoveredValue(option);
                                  // }}
                                  disableClearable
                                  fullWidth
                                  onBlur={() => {
                                    if (unitTypeHoveredValue) {
                                      handleDimensionsInputChange(
                                        'UnitType',
                                        index,
                                        unitTypeHoveredValue
                                      );
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box style={{ fontSize: 13 }} {...props}>
                                      {option}
                                    </Box>
                                  )}
                                  // sx={{ m: 1 }}
                                  size='small'
                                  options={
                                    shipTypeValue === 'Parcel'
                                      ? ParcelUnitTypeArr
                                      : LTLUnitTypeArr
                                  }
                                  value={
                                    // shipTypeValue === 'Parcel'
                                    //   ? ParcelUnitTypeArr[0]
                                    //   :
                                    items.UnitTypeValue
                                  }
                                  onChange={(event, newValue) => {
                                    handleDimensionsInputChange(
                                      'UnitType',
                                      index,
                                      newValue
                                    );
                                  }}
                                  onInputChange={(event, newValue) => {
                                    handleDimensionsInputChange(
                                      'UnitType',
                                      index,
                                      newValue
                                    );
                                  }}
                                  // sx={{ marginLeft: 1 }}
                                  renderInput={params => (
                                    <TextField
                                      fullWidth
                                      onKeyDown={e => {
                                        if (
                                          e.key === 'Tab' &&
                                          unitTypeHoveredValue
                                        ) {
                                          // e.preventDefault(); // prevent the default Tab behavior
                                          handleDimensionsInputChange(
                                            'UnitType',
                                            e,
                                            unitTypeHoveredValue
                                          );
                                        }
                                      }}
                                      // type='number'
                                      // sx={{ width: 120 }}
                                      // sx={
                                      //   shipTypeValue === 'LTL'
                                      //     ? { width: 120 }
                                      //     : { width: 190 }
                                      // }
                                      variant='outlined'
                                      {...params}
                                      InputProps={{
                                        style: { fontSize: 12, height: 32 },
                                        ...params.InputProps
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={shipTypeValue !== 'Parcel' ? 4 : 6}
                                    lg={shipTypeValue !== 'Parcel' ? 4 : 6}
                                    xl={shipTypeValue !== 'Parcel' ? 4 : 6}
                                  >
                                    <Label
                                      style={
                                        shipTypeValue === 'Parcel'
                                          ? {
                                              margin: '10px 0px 6px 0px',
                                              fontWeight: 700,
                                              fontSize: 12
                                            }
                                          : {
                                              margin: '6px 0px 6px 0px',
                                              fontWeight: 700,
                                              fontSize: 12
                                            }
                                      }
                                    >
                                      # of Units
                                    </Label>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={shipTypeValue === 'LTL' ? 8 : 6}
                                    lg={shipTypeValue === 'LTL' ? 8 : 6}
                                    xl={shipTypeValue === 'LTL' ? 8 : 6}
                                  >
                                    <Label
                                      style={
                                        shipTypeValue === 'Parcel'
                                          ? {
                                              margin: '10px 0px 6px 0px',
                                              fontWeight: 700,
                                              fontSize: 12
                                            }
                                          : {
                                              margin: '6px 0px 6px 0px',
                                              fontWeight: 700,
                                              fontSize: 12
                                            }
                                      }
                                    >
                                      # of Pieces
                                    </Label>
                                  </Grid>
                                </Grid>
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    error={Boolean(
                                      validationErrors['numberOfUnits']
                                    )}
                                    helperText={
                                      validationErrors['numberOfUnits']
                                    }
                                    FormHelperTextProps={{
                                      style: {
                                        whiteSpace: 'nowrap',
                                        margin: '0 0 -15 0'
                                      }
                                    }}
                                    value={items.NmbOfUnits}
                                    onChange={e =>
                                      handleDimensionsInputChange(
                                        'NmbOfUnits',
                                        index,
                                        e
                                      )
                                    }
                                    size='small'
                                    sx={
                                      isMobile
                                        ? {
                                            '& input[type=number]::-webkit-outer-spin-button':
                                              {
                                                ...spinnerStyles
                                              },
                                            '& input[type=number]::-webkit-inner-spin-button':
                                              {
                                                ...spinnerStyles
                                              }
                                          }
                                        : {}
                                    }
                                    type='number'
                                    inputProps={{
                                      style: {
                                        height: 15,
                                        fontSize: 12
                                      },
                                      min: 0
                                    }}
                                  />

                                  <TextField
                                    fullWidth
                                    error={Boolean(validationErrors['Pieces'])}
                                    helperText={validationErrors['Pieces']}
                                    FormHelperTextProps={{
                                      style: {
                                        whiteSpace: 'nowrap',
                                        margin: '0 0 -15 0'
                                      }
                                    }}
                                    value={items.Pieces}
                                    onChange={e =>
                                      handleDimensionsInputChange(
                                        'Pieces',
                                        index,
                                        e
                                      )
                                    }
                                    size='small'
                                    sx={
                                      isMobile
                                        ? {
                                            '& input[type=number]::-webkit-outer-spin-button':
                                              {
                                                ...spinnerStyles
                                              },
                                            '& input[type=number]::-webkit-inner-spin-button':
                                              {
                                                ...spinnerStyles
                                              }
                                          }
                                        : { ml: 1 }
                                    }
                                    type='number'
                                    inputProps={{
                                      style: {
                                        height: 15,
                                        fontSize: 12
                                      },
                                      min: 0
                                    }}
                                  />

                                  {shipTypeValue !== 'Parcel' && (
                                    <>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            sx={{ ml: 2 }}
                                            checked={items.StackAble}
                                            value={items.StackAble}
                                            onChange={(e, c) =>
                                              // handlePickUpServicesChange('Stackable')
                                              handleDimensionsInputChange(
                                                'Stackable',
                                                index,
                                                c
                                              )
                                            }
                                            color='primary'
                                            name='hazmat'
                                          />
                                        }
                                      />
                                      <Typography
                                        style={{
                                          margin: '0 0 0 -20',
                                          fontSize: 12,
                                          // whiteSpace: 'nowrap',
                                          fontWeight: 700
                                        }}
                                      >
                                        Stackable
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={
                                  shipTypeValue === 'LTL' ||
                                  selectedOptions === ''
                                    ? 3
                                    : 4
                                }
                                xl={
                                  shipTypeValue === 'LTL' ||
                                  selectedOptions === ''
                                    ? 3
                                    : 4
                                }
                              >
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12
                                  }}
                                >
                                  Dimensions
                                </Label>
                                <Grid sx={{ display: 'flex' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(
                                      validationErrors['PlengthError']
                                    )}
                                    helperText={
                                      validationErrors['PlengthError']
                                    }
                                    FormHelperTextProps={{
                                      style: {
                                        whiteSpace: 'nowrap',
                                        margin: '0 0 -10 0'
                                      }
                                    }}
                                    value={items.Length}
                                    onChange={e =>
                                      handleDimensionsInputChange(
                                        'Length',
                                        index,
                                        e
                                      )
                                    }
                                    type='number'
                                    onKeyPress={handleKeyPress}
                                    // className='inputRounded_Length'
                                    size='small'
                                    // sx={{ width: 95 }}
                                    id='outlined-password-input'
                                    label='Length (inches)'
                                    className={classes.input}
                                    // autoComplete='current-password'
                                    // value={'L'}
                                    InputLabelProps={{
                                      style: { fontSize: 11 }
                                    }}
                                    inputProps={{
                                      style: {
                                        height: 15,
                                        fontSize: 12,
                                        textAlign: 'center'
                                      }
                                    }}
                                  />
                                  <TextField
                                    fullWidth
                                    error={Boolean(
                                      validationErrors['PwidthError']
                                    )}
                                    helperText={validationErrors['PwidthError']}
                                    FormHelperTextProps={{
                                      style: {
                                        whiteSpace: 'nowrap',
                                        margin: '0 0 -10 0'
                                      }
                                    }}
                                    value={items.Width}
                                    onChange={e =>
                                      handleDimensionsInputChange(
                                        'Width',
                                        index,
                                        e
                                      )
                                    }
                                    type='number'
                                    onKeyPress={handleKeyPress}
                                    // className='inputRounded_Width'
                                    className={classes.input}
                                    size='small'
                                    // sx={{ width: 95 }}
                                    id='outlined-password-input'
                                    label='Width (inches)'
                                    autoComplete='current-password'
                                    // value={'W'}
                                    InputLabelProps={{
                                      style: { fontSize: 11 }
                                    }}
                                    inputProps={{
                                      style: {
                                        height: 15,
                                        fontSize: 12,
                                        textAlign: 'center'
                                      }
                                    }}
                                  />
                                  <TextField
                                    fullWidth
                                    error={Boolean(
                                      validationErrors['PheightError']
                                    )}
                                    helperText={
                                      validationErrors['PheightError']
                                    }
                                    FormHelperTextProps={{
                                      style: {
                                        whiteSpace: 'nowrap',
                                        margin: '0 0 -10 0'
                                      }
                                    }}
                                    value={items.Height}
                                    onChange={e =>
                                      handleDimensionsInputChange(
                                        'Height',
                                        index,
                                        e
                                      )
                                    }
                                    type='number'
                                    onKeyPress={handleKeyPress}
                                    className={classes.input}
                                    // className='inputRounded_Height'
                                    size='small'
                                    // sx={{ width: 95 }}
                                    id='outlined-password-input'
                                    label='Height (inches)'
                                    autoComplete='current-password'
                                    // value={'H'}
                                    InputLabelProps={{
                                      style: { fontSize: 11 }
                                    }}
                                    inputProps={{
                                      style: {
                                        height: 15,
                                        fontSize: 12,
                                        textAlign: 'center'
                                      }
                                    }}
                                  />
                                </Grid>
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#1976d2',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '8 0 10 0'
                                  }}
                                >
                                  <u>
                                    {index + 1 === arr.length && (
                                      <span>
                                        Total Shipment Weight{' '}
                                        {totalDimensionsWeightSum > 0 &&
                                          totalDimensionsWeightSum}{' '}
                                        (lbs)
                                      </span>
                                    )}
                                  </u>
                                </Typography>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={1.5}
                                xl={1.5}
                              >
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12
                                  }}
                                >
                                  Gross Weight (lbs)
                                </Label>
                                <TextField
                                  fullWidth
                                  className={classes.inputRoot}
                                  // inputRef={enterWeight}
                                  // error={Boolean(
                                  //   validationErrors['newAddedDimensionsRow']
                                  // )}
                                  // helperText={
                                  //   validationErrors['newAddedDimensionsRow']
                                  // }
                                  FormHelperTextProps={{
                                    style: {
                                      whiteSpace: 'nowrap',
                                      margin: '0 0 0 0'
                                    }
                                  }}
                                  value={grossWeight}
                                  onChange={e => setGrossWeight(e.target.value)}
                                  size='small'
                                  // sx={{ ml: 0.5 }}
                                  // sx={
                                  //   shipTypeValue === 'LTL'
                                  //     ? { width: 100 }
                                  //     : { width: 190 }
                                  // }
                                  // id='outlined-password-input'
                                  type='number'
                                  // autoComplete='current-password'
                                  InputLabelProps={{
                                    style: { fontSize: 12 }
                                  }}
                                  InputProps={{
                                    // min: 0,
                                    style: {
                                      height: 32,
                                      fontSize: 12
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={1.5}
                                xl={1.5}
                              >
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12,
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Freight Class
                                </Label>
                                <Autocomplete
                                  fullWidth
                                  // onHighlightChange={(event, option) => {
                                  //   setFreightClassHoveredValue(option);
                                  // }}
                                  disableClearable
                                  onBlur={() => {
                                    if (freightClassHoveredValue) {
                                      handleDimensionsInputChange(
                                        'Class',
                                        index,
                                        freightClassHoveredValue
                                      );
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box style={{ fontSize: 13 }} {...props}>
                                      {option}
                                    </Box>
                                  )}
                                  name='Class'
                                  size='small'
                                  options={ClassArray}
                                  // defaultValue={'50'}
                                  value={items.Class}
                                  onChange={(event, newValue) =>
                                    handleDimensionsInputChange(
                                      'Class',
                                      index,
                                      newValue
                                    )
                                  }
                                  onInputChange={(event, newValue) =>
                                    handleDimensionsInputChange(
                                      'Class',
                                      index,
                                      newValue
                                    )
                                  }
                                  // sx={{ marginLeft: 1 }}
                                  renderInput={params => (
                                    <TextField
                                      onKeyDown={e => {
                                        if (
                                          e.key === 'Tab' &&
                                          freightClassHoveredValue
                                        ) {
                                          // e.preventDefault(); // prevent the default Tab behavior
                                          handleDimensionsInputChange(
                                            'Class',
                                            e,
                                            freightClassHoveredValue
                                          );
                                        }
                                      }}
                                      // sx={{ width: 90 }}
                                      // placeholder='Payment Type'
                                      variant='outlined'
                                      {...params}
                                      InputProps={{
                                        style: { fontSize: 12, height: 32 },
                                        ...params.InputProps
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12
                                  }}
                                >
                                  Packaging Type
                                </Label>
                                <Autocomplete
                                  // onHighlightChange={(event, option) => {
                                  //   setUnitTypeHoveredValue(option);
                                  // }}
                                  disableClearable
                                  fullWidth
                                  onBlur={() => {
                                    if (unitTypeHoveredValue) {
                                      handleDimensionsInputChange(
                                        'UnitType',
                                        index,
                                        unitTypeHoveredValue
                                      );
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box style={{ fontSize: 13 }} {...props}>
                                      {option}
                                    </Box>
                                  )}
                                  // sx={{ m: 1 }}
                                  size='small'
                                  options={
                                    shipTypeValue === 'Parcel'
                                      ? ParcelUnitTypeArr
                                      : LTLUnitTypeArr
                                  }
                                  value={
                                    // shipTypeValue === 'Parcel'
                                    //   ? ParcelUnitTypeArr[0]
                                    //   :
                                    items.UnitTypeValue
                                  }
                                  onChange={(event, newValue) => {
                                    handleDimensionsInputChange(
                                      'UnitType',
                                      index,
                                      newValue
                                    );
                                  }}
                                  onInputChange={(event, newValue) => {
                                    handleDimensionsInputChange(
                                      'UnitType',
                                      index,
                                      newValue
                                    );
                                  }}
                                  // sx={{ marginLeft: 1 }}
                                  renderInput={params => (
                                    <TextField
                                      fullWidth
                                      onKeyDown={e => {
                                        if (
                                          e.key === 'Tab' &&
                                          unitTypeHoveredValue
                                        ) {
                                          // e.preventDefault(); // prevent the default Tab behavior
                                          handleDimensionsInputChange(
                                            'UnitType',
                                            e,
                                            unitTypeHoveredValue
                                          );
                                        }
                                      }}
                                      // type='number'
                                      // sx={{ width: 120 }}
                                      // sx={
                                      //   shipTypeValue === 'LTL'
                                      //     ? { width: 120 }
                                      //     : { width: 190 }
                                      // }
                                      variant='outlined'
                                      {...params}
                                      InputProps={{
                                        style: { fontSize: 12, height: 32 },
                                        ...params.InputProps
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={2.5}
                                xl={2.5}
                              >
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12,
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Requested Pickup Date
                                </Label>
                                <TextField
                                  fullWidth
                                  onChange={e =>
                                    setShipmentDate(e.target.value)
                                  }
                                  size='small'
                                  // sx={
                                  //   shipTypeValue === 'LTL'
                                  //     ? { width: 170, marginRight: 1 }
                                  //     : { width: 290 }
                                  // }
                                  // disabled
                                  id='outlined-password-input'
                                  defaultValue={shipmentDate}
                                  type='date'
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  inputProps={{
                                    min: today,
                                    style: {
                                      fontSize: 12
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={2.5}
                                xl={2.5}
                              >
                                <Label
                                  style={{
                                    margin: '10px 0px 6px 0px',
                                    fontWeight: 700,
                                    fontSize: 12,
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Estimated Delivery Date
                                </Label>
                                <TextField
                                  placeholder='MMM, DD, YYYY'
                                  fullWidth
                                  onChange={e =>
                                    setDeliveryDate(e.target.value)
                                  }
                                  size='small'
                                  // sx={
                                  //   shipTypeValue === 'LTL'
                                  //     ? { width: 170, marginRight: 1 }
                                  //     : { width: 290 }
                                  // }
                                  // disabled
                                  id='outlined-password-input'
                                  defaultValue={deliveryDate}
                                  type='date'
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  inputProps={{
                                    min: today,
                                    style: {
                                      fontSize: 12
                                    }
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                                <Chip
                                  variant='outlined'
                                  sx={{
                                    width: '100%',
                                    borderRadius: '0.5',
                                    mt: 4
                                  }}
                                  fullWidth
                                  label={
                                    <FormControlLabel
                                      fullWidth
                                      control={
                                        <Switch
                                          fullWidth
                                          checked={hazmatSwitch}
                                          value={hazmatSwitch}
                                          sx={switchStyle}
                                          onChange={() =>
                                            handlePickUpServicesChange('hazmat')
                                          }
                                          name='Hazardous '
                                        />
                                      }
                                      label={
                                        <Typography
                                          style={{
                                            fontSize: 12,
                                            fontWeight: 700
                                          }}
                                        >
                                          Hazardous
                                        </Typography>
                                      }
                                    />
                                  }
                                />
                              </Grid> */}
                            </>
                          )}
                        </Grid>
                      ))
                    )}
                  </>
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    padding: 0
                  }}
                >
                  <div
                    style={
                      (pickUpChecked || deliveryChecked) &&
                      shipTypeValue === 'LTL'
                        ? {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                            // marginTop: '1vw'
                          }
                        : {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                            // marginTop: '10vw'
                          }
                    }
                  >
                    <Button
                      style={
                        isMobile ? { width: '100%', margin: 7 } : { margin: 5 }
                      }
                      type='submit'
                      // disabled={
                      //   !shipFromFinalAddress ||
                      //   !shipToFinalAddress ||
                      //   !shipTypeValue ||
                      //   !shipLocationValue ||
                      //   !paymentTypeValue
                      //     ? true
                      //     : false
                      // }
                      onClick={e => handleGetRates(e)}
                      // variant='contained'
                      color='Blue'
                      size={isMobile ? 'sm' : ''}
                    >
                      {getRateLoading ? 'Loading ...' : 'Get Rates'}
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </Paper>
          </Grid>
        </Grid>
        {/* )} */}
      </div>
    </div>
  );
}

export default QuickQuote;
