
import { Box } from "@material-ui/core";
import { Badge, Button, Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";

import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MaterialReactTable from "material-react-table";
import NotificationSettings from "./NotificationSettings";


function Settings() {


    const [tabValue, setTabValue] = useState(0);
    const isMobile = useIsMobile();

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const notificationColumns = useMemo(
        () => [
            {
                accessorKey: 'name',
                id: 'name',
                header: 'Notification Name'
            },
        ],
        []
    );

    


    return (
        <Box sx={{ p: 2 }}>



            <Box
                sx={{
                    borderRadius: 6
                }}
                className="bg-white w-100 mb-2"
            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label='scrollable tabs example'
                    allowScrollButtonsMobile
                    sx={{
                        ml: 2,
                        '& .MuiTabs-scrollButtons': {
                            '&.Mui-disabled': { opacity: 0.3 }
                        }
                    }}
                >
                    <Tab
                        sx={{
                            fontWeight: 600,
                            fontSize: 14,
                        }}
                        label=<div className='d-flex align-items-center'>
                            <NotificationsIcon />
                            <div style={{ marginLeft: 3 }}>Notifications</div>
                        </div>
                        {...a11yProps(0)}
                    />
                </Tabs>
            </Box>

            {
                tabValue === 0 ?

                    <NotificationSettings />
                    :
                    <Box> </Box>
            }







        </Box>
    )
}

export default Settings;