import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { AppNotificationMarkAsRead, GetAagencyUserDetails } from "../../apis/Agency";
import { useDispatch, useSelector } from "react-redux";

const NotificationDialog = ({ notifView, isOpen, handleClose}) => {


    // const [open, setOpen] = useState(isOpen);

    // const handleClose = () => {
    //     setOpen(false);
    // };


    // const handleOpenNotification = () => {

    //     if (indicator) {
    //         setGlobalNotification({
    //             title: title,
    //             description: description,
    //             time: time
    //         })
    //         setGlobalOpen(true)
    //     }
    //     else {
    //         setOpen(true);
    //     }


    //     if (status) {
    //         AppNotificationMarkAsRead(id).then(res => {
    //         }).catch(er => {
    //             console.log(er)
    //         })
    //     }

    // }

    return (


        <Dialog
            PaperProps={{
                style: {
                    minWidth: '40vw',
                    height: 'fit-content',
                    maxHeight: '80vh'
                }
            }}
            className='InnerDialog'
            open={isOpen}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >

            <DialogTitle id='alert-dialog-title'>{notifView?.title || 'View Notification'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    <Box>
                        <div dangerouslySetInnerHTML={{ __html: notifView?.description }} />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="justify-content-between">
                <p style={{ fontSize: "12px", color: "#aaa", margin: 0, }}><span className="text-black">Recieved at:</span> {notifView?.time}</p>
                <Button size='small' variant='outlined' color='error' onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    );

    // return (
    //     <Dialog
    //         PaperProps={{
    //             style: {
    //                 minWidth: '40vw',
    //                 height: 'fit-content',
    //                 maxHeight: '80vh'
    //             }
    //         }}
    //         className='InnerDialog'
    //         open={open}
    //         onClose={handleClose}
    //         aria-labelledby='alert-dialog-title'
    //         aria-describedby='alert-dialog-description'
    //     >
    //         <DialogTitle id='alert-dialog-title'>{title || 'View Notification'}</DialogTitle>
    //         <DialogContent>
    //             <DialogContentText id='alert-dialog-description'>

    //                 <Box>

    //                     <div dangerouslySetInnerHTML={{ __html: description }} />
    //                 </Box>

    //             </DialogContentText>
    //         </DialogContent>
    //         <DialogActions className="justify-content-between">

    //             <p style={styles.timestamp}><span className="text-black">Recieved at:</span> {time}</p>
    //             <Button size='small' variant='outlined' color="error" onClick={handleClose}>
    //                 Cancel
    //             </Button>
    //         </DialogActions>
    //     </Dialog>
    // );
};

const styles = {

    newIndicator: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#007bff",
        marginRight: "10px",
        marginTop: "5px",
    },

    readIndicator: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#aaaaaa50",
        marginRight: "10px",
        marginTop: "5px",
    },


    title: {
        margin: 0,
        fontWeight: "bold",
        color: "#333",
    },
    subtitle: {
        margin: "5px 0 0 0",
        fontWeight: "bold",
        color: "#555",
    },
    description: {
        margin: "5px 0 0 0",
        color: "#888",
        fontSize: "14px",
    },
    timestamp: {
        fontSize: "12px",
        color: "#aaa",
        margin: 0,
    },
};

export default NotificationDialog;




