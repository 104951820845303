import React, { useEffect, useRef } from 'react';
import { Alert, Box, Button, Skeleton, Tab, Tabs } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import './GetRates.css';
import { useState } from 'react';
import { Typography } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import SendIcon from '@mui/icons-material/Send';
import {
  GetQuote,
  GetQuoteDetail,
  LogTLShipperResponse,
  ResendQuote
} from '../../apis/AgencyWithoutAuthHeaders';
import CancelIcon from '@mui/icons-material/Cancel';
import SortIcon from '@mui/icons-material/Sort';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ErrorToast, successToast } from '../../components/Toasts';
import { base_logo_url } from '../../apis/constants';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';

function ShipmentTabPanel({
  rateType,
  previousRowDetails,
  handleViewTLRates,
  TLQuoteId,
  carriers,
  handleListItemClick,
  selectedIndex,
  isLoading,
  ApiFailed,
  ApiErrorMessage
}) {
  const [negotitateRate, setNegotiateRate] = useState(false);
  const [carrierName, setCarrierName] = useState('');
  const [carrierLogo, setCarrierLogo] = useState('');
  const [userInput, setUserInput] = useState('');
  const [userInputArray, setUserInputArray] = useState([]);
  const [showInputValue, setShowInputValue] = useState(false);
  const [shipperSessionId, setShipperSessionId] = useState('');

  const [htmlApiRes, setHtmlApiRes] = useState('');
  const [shipperApiMsgs, setShipperApiMsgs] = useState('');
  const [carrierApiMsgs, setCarrierApiMsgs] = useState('');
  const [filteredCarriers, setFilteredCarriers] = useState([]);

  const customSort = (a, b) => {
    const order = ['RESPONDED', 'PENDING'];
    const indexA = order.indexOf(a.carrierResponseStatus);
    const indexB = order.indexOf(b.carrierResponseStatus);
    return indexB - indexA;
  };
  useEffect(() => {
    // let tempcarriers = carriers.sort(
    //   (a, b) => a.active.quoteValue - b.active.quoteValue
    // );
    const sortedArray = carriers.sort(customSort);
    setFilteredCarriers(sortedArray);
  }, [carriers]);

  useEffect(() => {
    const sortedItems = filteredCarriers.sort((a, b) => {
      if (
        a.carrierResponseStatus === 'PENDING' &&
        b.carrierResponseStatus !== 'PENDING'
      ) {
        return 1; // "pending" item comes last
      } else if (
        a.carrierResponseStatus !== 'PENDING' &&
        b.carrierResponseStatus === 'PENDING'
      ) {
        return -1; // "pending" item comes first
      } else {
        return 0; // No change in order for other statuses
      }
    });
    setFilteredCarriers(sortedItems);
  }, [filteredCarriers]);

  const handleClick = e => {
    e.preventDefault();
    // Perform your desired action on item click
    // console.log('Item clicked:', item);
  };
  const handleSuccessResponse = (apiRes, action) => {
    console.log(apiRes.data.data.details, 'apiResapiRes');
    action === 'resend' &&
      successToast('The email has been successfully resent.');
    const shipperData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'shipper'
    );
    const carrierData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'carrier'
    );
    // setHtmlApiRes(apiRes.data.data);
    setShipperApiMsgs(apiRes.data.data.details);
    setCarrierApiMsgs(carrierData);
    // setHtmlApiRes(apiRes.data.data.actionData);
    // setActionByName(apiRes.data.data.actionBy);
  };
  const ShipperQuoteReqHandler = (shipperSessionId, action) => {
    GetQuoteDetail(shipperSessionId)
      .then(res => res.status === 200 && handleSuccessResponse(res, action))
      .catch(err => console.log(err, 'QuoteTLErr------>>>.'));
  };
  const handleNegotiateRate = (e, items, action) => {
    setShipperSessionId(items.sessionId);
    if (
      (items?.carrierResponseStatus?.toLowerCase() === 'responded' ||
        items?.carrierResponseStatus?.toLowerCase() === 'accepted') &&
      action === 'update_chat'
    ) {
      ShipperQuoteReqHandler(items.sessionId);
    }
    if (
      items?.carrierResponseStatus?.toLowerCase() === 'pending' &&
      action === 'update_chat'
    ) {
      ResendQuote(items.sessionId)
        .then(
          res =>
            res.status === 200 &&
            ShipperQuoteReqHandler(items.sessionId, 'resend')
        )
        .catch(err => console.log(err, 'Resend Err---->>>>'));
    }

    setCarrierName(items?.carrierName);
    setCarrierLogo(items?.logo);
    if (
      action === 'view_chat' ||
      items?.carrierResponseStatus?.toLowerCase() === 'responded' ||
      items?.carrierResponseStatus?.toLowerCase() === 'accepted'
    ) {
      setNegotiateRate(true);
    }
  };

  const handleUserInput = input => {
    setUserInput(input);
  };

  const handleChatRequestApi = () => {
    const chatObj = {
      sessionId: shipperSessionId,
      charges: 0,
      pickupNumber: '',
      pickupDate: '',
      estimatedDeliveryDate: '',
      comments: userInput
    };
    LogTLShipperResponse(chatObj)
      .then(
        res => res.status === 200 && ShipperQuoteReqHandler(shipperSessionId)
      )
      .catch(err => ErrorToast(err?.message));
  };
  const handleShowUserInputMsgs = () => {
    setShowInputValue(true);
    // let temp = [...userInputArray];
    // temp.push({ msg: userInput });
    // setUserInputArray(temp);
    getCurrentDateTime();
    setUserInput('');
    if (userInput !== '') {
      handleChatRequestApi();
    }
  };
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleShowUserInputMsgs();
    }
  };
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    // alert('scrollled');
    bottomRef.current &&
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Scroll to the bottom whenever userInputArray is updated
    scrollToBottom();
  }, [shipperApiMsgs, negotitateRate]);

  const getCurrentDateTime = (() => {
    let initialTimestamp = null;

    return () => {
      if (!initialTimestamp) {
        initialTimestamp = new Date();
        let temp = [...userInputArray];
        temp.push({ timeStamp: initialTimestamp, msg: userInput });
        // temp.push({ msg: userInput });
        setUserInputArray(temp);
      }

      const now = new Date();
      const diff = now - initialTimestamp;

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];

      const dayName = daysOfWeek[initialTimestamp.getDay()];
      const monthName = months[initialTimestamp.getMonth()];
      const day = initialTimestamp.getDate();
      const year = initialTimestamp.getFullYear();

      const hours = initialTimestamp.getHours();
      const minutes = initialTimestamp.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? '0' : ''
      }${minutes} ${ampm}`;
      let temp = [...userInputArray];
      temp.push({
        timeStamp: ` ${monthName} ${day}, ${year} ${formattedTime}`,
        msg: userInput
      });
      // temp.push({ msg: userInput });
      setUserInputArray(temp);
      // return ` ${monthName} ${day}, ${year} ${formattedTime}`;
    };
  })();

  function formatDateTime(dateTime) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const dayName = daysOfWeek[dateTime.getDay()];
    const monthName = months[dateTime.getMonth()];
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();

    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedTime = `${hours % 12 || 12}:${
      minutes < 10 ? '0' : ''
    }${minutes} ${ampm}`;

    return `${dayName}, ${monthName} ${day}, ${year} ${formattedTime}`;
  }

  const handleSynchRatesRequest = () => {
    setSynchRotate(true);
    GetQuote(TLQuoteId)
      .then(res => {
        res.status === 200 && handleViewTLRates(res.data.data);
        setSynchRotate(false);
      })
      .catch(err => console.log(err, 'TL quote Err----->>>>'));
  };

  const handleDateTimeShow = (timeStamp, type) => {
    console.log(timeStamp, type, 'timeStamp, type');
    if (timeStamp !== null) {
      const dateTimeString = timeStamp + 'Z';
      const dateTime = new Date(dateTimeString);
      const date = new Date(dateTimeString);
      // const timeOptions = {
      //   hour: 'numeric',
      //   minute: '2-digit',
      //   // second: '2-digit',
      //   hour12: true,
      //   weekday: 'short',
      //   // year: 'numeric',
      //   month: 'short',
      //   day: 'numeric'
      // };
      const dateOptions = {
        // weekday: 'short',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      };

      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const timeOptions = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: userTimeZone
      }).format(dateTime);

      const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions);
      const formattedDate = date.toLocaleDateString(undefined, dateOptions);

      if (type === 'time') {
        return timeOptions;
      }
      if (type === 'date') {
        return formattedDate;
      }
    }
  };
  const [sortValue, setSortValue] = useState(0);
  const [filterKey, setFilterKey] = useState('*');
  const [sortKey, setSortKey] = useState('price');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [synchRotate, setSynchRotate] = useState(false);
  const loadingArraySkeleton = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const handleSortToggle = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };
  const handleSortValueChange = (event, newValue) => {
    setSortValue(newValue);
  };
  const applyStatusSort = (a, b) => {
    if (
      a.carrierResponseStatus === 'PENDING' &&
      b.carrierResponseStatus !== 'PENDING'
    ) {
      return 1; // "pending" item comes last
    } else if (
      a.carrierResponseStatus !== 'PENDING' &&
      b.carrierResponseStatus === 'PENDING'
    ) {
      return -1; // "pending" item comes first
    } else {
      return 0; // No change in order for other statuses
    }
  };
  const applySort = (rowA, rowB) => {
    switch (sortKey) {
      case 'price':
        return sortOrder === 'ASC'
          ? rowA.quoteValue - rowB.quoteValue
          : rowB.quoteValue - rowA.quoteValue;
      case 'days':
        const dateA = new Date(rowA.deliveryDate);
        const dateB = new Date(rowB.deliveryDate);

        const daysA = isNaN(dateA.getTime()) ? 9999 : dateA.getTime();
        const daysB = isNaN(dateB.getTime()) ? 9999 : dateB.getTime();

        return sortOrder === 'ASC' ? daysA - daysB : daysB - daysA;
      case 'name':
        return sortOrder === 'ASC'
          ? rowA.carrierName.localeCompare(rowB.carrierName)
          : rowB.carrierName.localeCompare(rowA.carrierName);
      default:
        return 0; // Default case: no sorting
    }
  };

  const sortedItems = filteredCarriers.sort(applySort).sort(applyStatusSort);
  const removeTableCss = finalRes => {
    const regexPattern = /<style[^>]*>([\s\S]*?)<\/style>/gi;

    // Remove table styles including borders
    const updatedHtml = finalRes.replace(regexPattern, (match, styles) => {
      const stylesWithoutTable = styles.replace(
        /(?:table|td|th)\s*\{[^}]*\}/gi,
        ''
      );
      return `<style>${stylesWithoutTable} table { font-size: 12px; } td { padding: 5px; }</style>`;
    });
    return updatedHtml;
  };

  const handleCorresHistory = apiRes => {
    // Regex pattern to capture and replace font-size in table styles
    const fontSizeToUpdate = removeTableCss(apiRes);

    return fontSizeToUpdate;

    // removeTableCss(updatedHtml);
  };

  const isMobile = useIsMobile();
  return (
    <div>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        style={{ width: 'auto' }}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        {/* <hr /> */}
        <div
          style={
            isMobile
              ? {
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  overflowX: 'scroll',
                  overflowY: 'hidden',
                  // justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginTop: 10,
                  boxShadow: '0.5px 0.5px 2px 0px',
                  borderRadius: 4,
                  height: 30
                }
              : {
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  justifyContent: 'start',
                  alignItems: 'center',
                  marginTop: 15,
                  boxShadow: '0.5px 0.5px 2px 0px',
                  borderRadius: 4,
                  height: 30
                }
          }
        >
          <Col>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Order Number:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.soNumber}
              </span>
            </Typography>
          </Col>
          <Col>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Ship from:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.originAddress}
              </span>
            </Typography>
          </Col>
          <Col>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Ship to:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.destinationAddress}
              </span>
            </Typography>
          </Col>
          {rateType !== 'Quick' && (
            <Col>
              <Typography
                style={{ fontSize: '13px' }}
                variant='h6'
                className='fw-bold text-black me-2'
              >
                Ship to Company:{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {previousRowDetails.destinationCompany}
                </span>
              </Typography>
            </Col>
          )}
          <Col style={{ textAlign: 'right' }}>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Net Weight:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.details[0].netWeight} lbs
              </span>
            </Typography>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Typography
              style={{ fontSize: '13px' }}
              variant='h6'
              className='fw-bold text-black me-2'
            >
              Gross Weight:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {previousRowDetails.details[0].grossWeight} lbs
              </span>
            </Typography>
          </Col>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
            boxShadow: '0.5px 0.5px 2px 0px',
            borderRadius: 8
            // height: 35
          }}
        >
          <div>
            <Button
              sx={
                isMobile
                  ? {
                      whiteSpace: 'nowrap',
                      textTransform: 'capitalize',
                      height: 25,
                      width: 40
                    }
                  : {
                      whiteSpace: 'nowrap',
                      textTransform: 'capitalize',
                      height: 25
                    }
              }
              variant='outlined'
              size={isMobile ? 'small' : 'medium'}
            >
              <Typography
                variant='h6'
                className='fw-bold text-black me-2'
                style={{ cursor: 'pointer', fontSize: '13px' }}
                onClick={() => handleSynchRatesRequest()}
                // onClick={() => window.location.reload()}
              >
                {!isMobile && synchRotate ? (
                  <RefreshIcon
                    sx={{ transform: 'rotateX(-190deg)', fontSize: '16px' }}
                  />
                ) : (
                  !isMobile && <RefreshIcon sx={{ fontSize: '16px' }} />
                )}
                {isMobile ? 'Refresh' : 'Refresh Rates'}
              </Typography>
            </Button>
          </div>
          <div
            style={
              isMobile
                ? {
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center'
                    // marginLeft: 1
                  }
                : {
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center'
                  }
            }
          >
            {' '}
            <Box className='d-flex' sx={{ alignItems: 'center' }}>
              <Typography
                variant='h6'
                className='fw-bold text-black me-2'
                style={{ cursor: 'pointer', fontSize: '13px' }}
                onClick={handleSortToggle}
              >
                {sortOrder === 'ASC' ? (
                  <SortIcon
                    style={{ transform: 'rotateX(180deg)', fontSize: '16px' }}
                  />
                ) : (
                  <SortIcon style={{ fontSize: '16px' }} />
                )}{' '}
                Sort
              </Typography>
              <Tabs
                // scrollButtons='auto'
                // allowScrollButtonsMobile
                // TabIndicatorProps={{
                //   style: isMobile ? { display: 'none' } : {}
                // }}
                // sx={isMobile ? { width: '60%', m: 0 } : {}}
                onChange={handleSortValueChange}
                value={sortValue}
                aria-label='Tabs where selection follows focus'
                selectionFollowsFocus
              >
                {/* icon={sortOrder === 'ASC' ? <SortIcon style={{ transform: 'rotateX(180deg)' }} /> : <SortIcon />} */}
                <Tab
                  sx={{
                    fontSize: '13px',
                    minWidth: 'auto',
                    textTransform: 'none'
                  }}
                  className='px-2 fw-bold'
                  iconPosition='start'
                  label='Price'
                  onClick={() => setSortKey('price')}
                />
                <Tab
                  sx={{
                    fontSize: '13px',
                    minWidth: 'auto',
                    textTransform: 'none'
                  }}
                  className='px-2 fw-bold'
                  iconPosition='start'
                  label='Dates'
                  onClick={() => setSortKey('days')}
                />
                <Tab
                  sx={{
                    fontSize: '13px',
                    minWidth: 'auto',
                    textTransform: 'none'
                  }}
                  className='px-2 fw-bold'
                  iconPosition='start'
                  label={sortOrder === 'ASC' ? 'Carrier A-Z' : 'Carrier Z-A'}
                  onClick={() => setSortKey('name')}
                />
              </Tabs>
            </Box>
          </div>
        </div>
        <hr />
        <Col xl={negotitateRate ? 7 : 12} lg={negotitateRate ? 7 : 12}>
          <div
            className='scroll'
            style={{ height: '60vh', overflowX: 'hidden', overflowY: 'scroll' }}
            // ref={divRef}
            // onScroll={() => setScrollPosition(divRef.current.scrollTop)}
            // onClick={handleButtonClick}
          >
            {isLoading
              ? sortedItems.map(items => (
                  <div
                    style={
                      isMobile
                        ? { margin: '13px 1px 0px 1px' }
                        : { margin: '20px' }
                    }
                    // className='Error_Carrier_div_LTL'
                    className={
                      negotitateRate
                        ? 'List_Carrier_div_Negotiate'
                        : 'List_Carrier_div'
                    }
                  >
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={
                          isLoading
                            ? {
                                width: 60,
                                fontSize: 12,
                                fontWeight: 700,
                                position: 'absolute',
                                margin: '-90 0 0 20',
                                backgroundColor: 'white',
                                whiteSpace: 'nowrap'
                              }
                            : {
                                width: 'auto',
                                fontSize: 12,
                                fontWeight: 700,
                                position: 'absolute',
                                margin: '-90 0 0 20',
                                backgroundColor: 'white',
                                whiteSpace: 'nowrap'
                              }
                        }
                      ></div>
                      <Col>
                        <Box
                          sx={{
                            height: 60,
                            margin: '20 0 0 17'
                          }}
                        >
                          <Skeleton
                            variant='circular'
                            width={isMobile ? 30 : 40}
                            height={isMobile ? 30 : 40}
                          />
                        </Box>
                      </Col>
                      <Col>
                        <div style={{ cursor: 'pointer' }}>
                          <h2>
                            <Box sx={isMobile ? { width: 40 } : { width: 120 }}>
                              <Skeleton />
                            </Box>
                          </h2>

                          <span
                            style={{
                              width: 'auto',
                              fontSize: 10,
                              fontWeight: 700,
                              position: 'absolute',
                              margin: '-14 0 0 0',
                              cursor: 'auto'
                            }}
                          >
                            <Box
                              sx={
                                isMobile
                                  ? { width: 50, marginTop: 0.5 }
                                  : { width: 100, marginTop: 0.5 }
                              }
                            >
                              <Skeleton />
                            </Box>
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 15
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: 11,
                                fontWeight: 700
                              }}
                            >
                              <Box
                                sx={isMobile ? { width: 40 } : { width: 110 }}
                              >
                                <Skeleton />
                              </Box>
                              <br />
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: 'normal'
                                }}
                              >
                                <Box
                                  sx={
                                    isMobile
                                      ? { width: 30, marginTop: -2 }
                                      : { width: 80, marginTop: -2 }
                                  }
                                >
                                  <Skeleton />
                                </Box>
                              </span>
                            </span>
                          </div>{' '}
                        </div>
                      </Col>
                      <Col>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 15
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: 11,
                                fontWeight: 700
                              }}
                            >
                              <Box
                                sx={isMobile ? { width: 40 } : { width: 110 }}
                              >
                                <Skeleton />
                              </Box>
                              <br />
                              <span
                                style={{
                                  fontSize: 11,
                                  fontWeight: 'normal'
                                }}
                              >
                                <Box
                                  sx={
                                    isMobile
                                      ? { width: 30, marginTop: -2 }
                                      : { width: 80, marginTop: -2 }
                                  }
                                >
                                  <Skeleton />
                                </Box>
                              </span>
                            </span>
                          </div>{' '}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))
              : sortedItems.map((items, index) => {
                  if (items) {
                    return (
                      <div
                        style={{
                          // display: 'flex',
                          // justifyContent: 'flex-start',
                          margin: '15 10 0 0'
                        }}
                      >
                        <List>
                          <ListItemButton
                            className={
                              negotitateRate
                                ? 'List_Carrier_div_Negotiate'
                                : 'List_Carrier_div'
                            }
                            onMouseDown={handleClick}
                            selected={selectedIndex === index}
                            onClick={event => {
                              handleListItemClick(event, index, items);
                              // handleNegotiateRate(event, items, 'view_chat');
                            }}
                          >
                            <Row
                              key={index}
                              className='Carrier_div'
                              style={
                                isMobile
                                  ? {
                                      display: 'flex',
                                      alignItems: 'center',
                                      fontSize: 10
                                    }
                                  : {
                                      display: 'flex',
                                      alignItems: 'center',
                                      height: '80px'
                                    }
                              }
                            >
                              <Col xs={3} sm={3} md={3} lg={3}>
                                <div
                                  style={
                                    isMobile
                                      ? {
                                          display: 'flex',
                                          flexDirection: 'column'
                                        }
                                      : { display: 'flex' }
                                  }
                                >
                                  <div>
                                    <img
                                      style={
                                        isMobile
                                          ? {
                                              height: 50,
                                              width: '50',
                                              objectFit: 'contain'
                                            }
                                          : {
                                              height: 70,
                                              width: '70',
                                              objectFit: 'contain'
                                            }
                                      }
                                      src={base_logo_url + items.carrierLogo}
                                      alt={''}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      marginTop: 20,
                                      marginLeft: 10,
                                      marginRight: 10,
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    {isMobile ? (
                                      <h6>{items.carrierName}</h6>
                                    ) : (
                                      <h5>{items.carrierName}</h5>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col xs={3} sm={3} md={3} lg={3}>
                                <div
                                  style={{
                                    textAlign: 'center',
                                    marginRight: '35%'
                                  }}
                                >
                                  <div>
                                    {isMobile ? (
                                      <h7>
                                        {items?.carrierResponseStatus?.toLowerCase() ===
                                          'responded' ||
                                        items?.carrierResponseStatus?.toLowerCase() ===
                                          'accepted'
                                          ? `$${items?.quoteValue}`
                                          : 'PENDING'}
                                      </h7>
                                    ) : (
                                      <h5>
                                        {items?.carrierResponseStatus?.toLowerCase() ===
                                          'responded' ||
                                        items?.carrierResponseStatus?.toLowerCase() ===
                                          'accepted'
                                          ? `$${items?.quoteValue}`
                                          : 'PENDING'}
                                      </h5>
                                    )}
                                  </div>
                                  <Button
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleNegotiateRate(
                                        e,
                                        items,
                                        'update_chat'
                                      );
                                    }}
                                    sx={{
                                      width: 90,
                                      fontSize: 9,
                                      textTransform: 'capitalize'
                                    }}
                                    variant='contained'
                                    size='small'
                                  >
                                    {items?.carrierResponseStatus?.toLowerCase() ===
                                      'responded' ||
                                    items?.carrierResponseStatus?.toLowerCase() ===
                                      'accepted'
                                      ? 'Negotiate'
                                      : 'Resend'}
                                  </Button>{' '}
                                  <br />
                                  {items?.referenceNumber && (
                                    <div style={{ marginTop: 5, fontSize: 12 }}>
                                      <b>Service type: </b>
                                      {items?.referenceNumber}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={3} sm={3} md={3} lg={3}>
                                {isMobile ? (
                                  <h7>Pickup Date</h7>
                                ) : (
                                  <h6>Pickup Date</h6>
                                )}
                                {isMobile && <br />}

                                {
                                  <h7>
                                    {items?.carrierResponseStatus?.toLowerCase() ===
                                      'responded' ||
                                    items?.carrierResponseStatus?.toLowerCase() ===
                                      'accepted'
                                      ? items?.quotePickupDate
                                      : 'PENDING'}
                                  </h7>
                                }
                              </Col>
                              <Col xs={3} sm={3} md={3} lg={3}>
                                {isMobile ? (
                                  <h7>Est Delivery Date</h7>
                                ) : (
                                  <h6>Est Delivery Date</h6>
                                )}
                                {isMobile && <br />}
                                <h7>
                                  {items?.carrierResponseStatus?.toLowerCase() ===
                                    'responded' ||
                                  items?.carrierResponseStatus?.toLowerCase() ===
                                    'accepted'
                                    ? items?.deliveryDate
                                    : 'PENDING'}
                                </h7>
                              </Col>
                            </Row>
                          </ListItemButton>
                        </List>
                      </div>
                    );
                  }
                })}
            {ApiFailed && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10%'
                }}
              >
                {/* <div class='alert alert-danger' role='alert'>
                  {ApiErrorMessage}
                </div> */}
                <Alert severity='error'>{ApiErrorMessage}</Alert>
              </div>
            )}
          </div>
        </Col>
        {negotitateRate && (
          <Col xl={5} lg={5}>
            <div className='Chat_Parent'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant='h6'>Chat</Typography>
                  <SyncOutlinedIcon
                    onClick={() => ShipperQuoteReqHandler(shipperSessionId)}
                    sx={{ cursor: 'pointer', color: '#4e79d2' }}
                  />
                </div>
                <CancelIcon
                  onClick={() => {
                    setNegotiateRate(false);
                    setHtmlApiRes('');
                  }}
                  sx={{ cursor: 'pointer', color: '#4e79d2' }}
                />
              </div>
              {/* <hr /> */}
              <div className='Chat_Child'>
                {shipperApiMsgs &&
                  shipperApiMsgs.map(carrierRes => (
                    <div
                      style={
                        carrierRes.actionByFlag.toLowerCase() === 'carrier'
                          ? {
                              display: 'flex',
                              justifyContent: 'start',
                              flexDirection: 'column',
                              marginLeft: 10,
                              marginRight: 10,
                              width: 400,
                              position: 'relative'
                            }
                          : {
                              display: 'flex',
                              justifyContent: 'end',
                              // flexDirection: 'column',
                              marginLeft: 10,
                              marginRight: 10,
                              position: 'relative'
                            }
                      }
                    >
                      {/* <div
                        className={
                          carrierRes.actionByFlag.toLowerCase() === 'carrier'
                            ? 'agency_manager_triangle'
                            : 'agency_carrier_triangle'
                        }
                      ></div> */}
                      {carrierRes.actionByFlag.toLowerCase() === 'carrier' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start'
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#eceff1',
                              borderRadius: '50%',
                              width: '45',
                              height: '45',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              marginRight: 10
                            }}
                          >
                            <img
                              style={{
                                borderRadius: '50%',
                                width: '45',
                                height: '45',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                objectFit: 'cover'
                              }}
                              alt=''
                              src={
                                base_logo_url + carrierApiMsgs[0]?.actionByImage
                              }
                            />
                          </div>
                          <div>
                            <b style={{ fontSize: 18 }}>
                              {carrierApiMsgs &&
                                carrierApiMsgs[0]?.actionByName}
                            </b>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                              {carrierApiMsgs[0]?.actionByDesignation}
                            </span>
                          </div>
                        </div>
                      )}
                      <div>
                        {carrierRes.actionByFlag.toLowerCase() ===
                          'shipper' && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'end'
                            }}
                          >
                            <div
                              style={{
                                marginLeft: 0,
                                marginRight: 1,
                                textAlign: 'right'
                              }}
                            >
                              <b style={{ fontSize: 18 }}>
                                {shipperApiMsgs &&
                                  shipperApiMsgs[0]?.actionByName}
                              </b>
                              <br />
                              <span style={{ fontWeight: 600 }}>
                                {shipperApiMsgs[0]?.actionByDesignation}
                              </span>
                              <br />
                            </div>
                            <div
                              style={{
                                backgroundColor: '#e0f2f1',
                                borderRadius: '50%',
                                width: '45',
                                height: '45',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 10
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: '50%',
                                  width: '45',
                                  height: '45',
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  objectFit: 'cover'
                                }}
                                alt=''
                                src={
                                  base_logo_url +
                                  shipperApiMsgs[0]?.actionByImage
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div
                          style={
                            carrierRes.actionByFlag.toLowerCase() === 'shipper'
                              ? {
                                  marginBottom: '10vh',
                                  backgroundColor: '#e0f2f1',
                                  borderRadius: '10px 0px 10px 10px',
                                  padding: '10px',
                                  transform: '(rotateX(180deg)'
                                }
                              : {
                                  marginLeft: 1,
                                  marginBottom: '10vh',
                                  backgroundColor: '#eceff1',
                                  padding: '10px',
                                  borderRadius: '0px 10px 10px 10px'
                                }
                          }
                        >
                          <div
                            style={{ fontSize: 13 }}
                            dangerouslySetInnerHTML={{
                              __html: handleCorresHistory(carrierRes.actionData)
                            }}
                          ></div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              fontSize: 11
                            }}
                          >
                            {handleDateTimeShow(carrierRes.date, 'time')}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <div ref={bottomRef} />
              </div>

              <div className='User_Input'>
                <input
                  className='User_Input'
                  placeholder='Type your message here ...'
                  type='text'
                  id='userInput'
                  name='userInput'
                  value={userInput}
                  onChange={e => handleUserInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <SendIcon
                  id='submitButton'
                  onClick={handleShowUserInputMsgs}
                  // onClick={() => handleShowUserInputMsgs()}
                  sx={{ fontSize: 30, marginBottom: 1 }}
                  className='User_Input_Icon'
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ShipmentTabPanel;
